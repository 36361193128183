// @flow

import React, { Suspense, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, Route, RouterHistory, Switch, withRouter } from 'react-router-dom'
import Cookies from 'js-cookie'

import 'firebase/auth'
import 'firebase/database'
import './index.less'
import 'react-notifications-component/dist/theme.css'
import { LOGIN_USER } from '~state/user/queries'
// Redux Actions
import * as actionTypes from '../../store/actions'
import { Dropdown, Icon, Menu, Spin, Steps } from 'antd'

import AuthRoute from '../../components/auth-route'
// Components
import Intercom from 'react-intercom'
import { SET_PATH_TO_REDIRECT, IS_PROID_IN_WARRANTY } from '../../store/actions'

import ReactNotification from 'react-notifications-component'
import Text from '~text'
import { connect } from 'react-redux'
// Icons
import facebookIconImg from '~images/fab-ico.png'
import useIsMobileWithWidth from '~utils/isMobileWithWidth'
import MediaDore from '~images/mediaDore.svg'
import firebase from 'firebase/app'
// Images
import logoImg from '~images/LOGO-header.png'
import portugalFlagIcon from '~images/icons/flags/pt.svg'
// HOC
import routes from '../../routes'
import twitterIconImg from '~images/twitterlogomudey.png'
import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import { titleManager } from '../../utils/titleManager'
import { CheckoutForm } from '../../components/stripe'
import NewFooter from '~components/footer/NewFooter'
import { extractParams } from '~utils/extractParams'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import HelpCrunch from '~components/helpcrunch'
import { CustomerlyProvider } from 'react-live-chat-customerly'
import CustomerlyChat from '~components/customerly'

const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID
const customerlyId = process.env.REACT_APP_CUSTOMERLY_ID;

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

firebase.initializeApp(config)

type PropsType = {
  client: any,
  history: RouterHistory,
  login: Function,
  logout: Function,
  wallet_token: any
}

function App(props: PropsType) {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const search = useLocation().search
  const history = useHistory()
  const state = useSelector(state => state)

  useEffect(() => {
    titleManager(location)
    if (
      location.pathname.includes('seguro-garantia') &&
      (_.isEmpty(location.search) || location.search.includes('overview'))
    ) {
      dispatch({ type: IS_PROID_IN_WARRANTY, payload: false })
    }
    if (!location.pathname.includes('seguro-garantia')) {
      dispatch({ type: IS_PROID_IN_WARRANTY, payload: false })
    }
  }, [location])

  const refreshUrl = location => {
    history.replace(location.pathname)
  }
  const { company } = extractParams(['company'], search)
  const isMobile = useIsMobileWithWidth('(max-width: 600px)')
  if (company) {
    Cookies.set('MUDEY_company', company, { domain: domain }, { expires: 1 }, { sameSite: 'strict' }, { secure: true })
    refreshUrl(location)
  }
  const domain = process.env.NODE_ENV === 'development' ? 'localhost' : '.mudey.pt'
  const wallet_token = Cookies.get('MUDEY_wallet_token', { domain: domain }) || null
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng)
  }
  const authState = useSelector((state: any) => state.status)
  const user = useSelector((state: any) => state.user)
  const firebaseUser = firebase.auth().currentUser
  const firebaseAuth = async () => {
    const wallet_token = Cookies.get('MUDEY_wallet_token', { domain: domain }) || null
    const wallet_uuid = Cookies.get('MUDEY_wallet_uuid', { domain: domain }) || null
    const wallet_email = Cookies.get('MUDEY_wallet_email', { domain: domain }) || null
    const wallet_name = Cookies.get('MUDEY_wallet_name', { domain: domain }) || null
    const app_token = Cookies.get('MUDEY_token', { domain: domain })
    const firebaseUser = firebase.auth().currentUser
    if (!firebaseUser && wallet_token) {
      Cookies.set(
        'MUDEY_token',
        wallet_token,
        { domain: domain },
        { expires: 1 },
        { sameSite: 'strict' },
        { secure: true }
      )
      Cookies.set(
        'MUDEY_uuid',
        wallet_uuid,
        { domain: domain },
        { expires: 1 },
        { sameSite: 'strict' },
        { secure: true }
      )
      Cookies.set(
        'MUDEY_email',
        wallet_email,
        { domain: domain },
        { expires: 1 },
        { sameSite: 'strict' },
        { secure: true }
      )
      const companyId = Cookies.get('MUDEY_company', { domain: domain }) || 'Mudey'
      const managerId = Cookies.get('MUDEY_manager', { domain: domain }) || ''

      const { data } = await props.client.query({
        query: LOGIN_USER,
        fetchPolicy: 'no-cache',
        variables: {
          companyId: companyId,
          managerId: managerId
        }
      })
      Cookies.set(
        'MUDEY_name',
        data.userLogin.firstName,
        { domain: domain },
        { expires: 1 },
        { sameSite: 'strict' },
        { secure: true }
      )
      Cookies.set(
        'MUDEY_company',
        data.userLogin.companyId,
        { domain: domain },
        { expires: 1 },
        { sameSite: 'strict' },
        { secure: true }
      )
      Cookies.set(
        'MUDEY_manager_handler',
        data.userLogin.managerId,
        { domain: domain },
        { expires: 1 },
        { sameSite: 'strict' },
        { secure: true }
      )
      dispatch({
        type: 'LOGIN',
        payload: {
          token: wallet_token,
          user: {
            email: wallet_email,
            name: data.userLogin.firstName,
            uuid: wallet_uuid
          }
        }
      })
    }
  }

  useEffect(() => {
    const autoLogin = () => {
      const wallet_token = Cookies.get('MUDEY_wallet_token', { domain: domain }) || null
      setTimeout(() => {
        if (!!wallet_token) {
          firebaseAuth()
        }
      }, 0)
    }
    if (!user) {
      autoLogin()
    }
  }, [user])

  const signOut = async () => {
    localStorage.removeItem('pathToRedirect')
    dispatch({ type: SET_PATH_TO_REDIRECT, payload: null })
    dispatch({ type: actionTypes.HAS_USER_LOGGED_OUT, payload: true })
    Cookies.remove('MUDEY_token', { domain: domain })
    Cookies.remove('MUDEY_wallet_token', { domain: domain })
    Cookies.remove('MUDEY_wallet_token')
    Cookies.remove('MUDEY_uuid', { domain: domain })
    Cookies.remove('MUDEY_email', { domain: domain })
    Cookies.remove('MUDEY_name', { domain: domain })
    Cookies.remove('MUDEY_wallet_uuid', { domain: domain })
    Cookies.remove('MUDEY_wallet_email', { domain: domain })
    Cookies.remove('MUDEY_wallet_name', { domain: domain })
    Cookies.remove('MUDEY_company', { domain: domain })
    firebase.auth().signOut()
    props.logout()
  }

  const renderSteps = () => {
    const { pathname } = location
    let step = -1

    if (pathname.indexOf('/simulacao') > -1) step = 0
    else if (pathname.indexOf('/resultados/pacotes') > -1 || pathname.indexOf('/resultados/comparar') > -1) step = 1
    else if (pathname.indexOf('/compra/resumo') > -1 || pathname.indexOf('/resultados/summary') > -1) step = 2

    return step >= 0 ? (
      <Steps current={step} progressDot size="small">
        <Steps.Step title="Questionário" />
        <Steps.Step title="Resultados" />
        <Steps.Step title="Compra" />
      </Steps>
    ) : (
      <div />
    )
  }

  const userDropdownMenu = (
    <Menu>
      {!wallet_token && (
        <Menu.Item key="0">
          <a href={process.env.REACT_APP_WALLET_URL}>Aceder Wallet</a>
        </Menu.Item>
      )}
      <Menu.Item key="1">
        <a onClick={() => signOut()}>Sair</a>
      </Menu.Item>
    </Menu>
  )

  const renderMobileLogo = () => {
    if (state?.is_proid_in_warranty === true) {
      return null
    } else {
      return (
        <div className="mobile-logo-wrapper">
          <img alt="Mudey" src={logoImg} title="Mudey" />
          <span style={{ color: '#666666' }}>Mediadores de Seguros</span>
        </div>
      )
    }
  }
  const renderDesktopLogo = () => {
    if (state?.is_proid_in_warranty === true) {
      return null
    } else {
      return <img alt="Mudey" src={MediaDore} title="Mudey" />
    }
  }

  return (
    <React.Fragment>
      <ReactNotification />
      {location.pathname != '/iframe-redirect-success' && (
        <header className="header">
          <div className="container">
            <div className="header-wrapper">
              <a href={process.env.REACT_APP_HP_URL}>{isMobile ? renderMobileLogo() : renderDesktopLogo()}</a>
              <div className="language-changer">
                <Text
                  align="center"
                  color={i18n.language === 'pt' ? '#895d85' : 'rgba(0, 0, 0, 0.4)'}
                  onClick={() => changeLanguage('pt')}
                  size={14}
                  uppercase>
                  pt
                </Text>
                <Text
                  align="center"
                  color={i18n.language === 'en' ? '#895d85' : 'rgba(0, 0, 0, 0.4)'}
                  onClick={() => changeLanguage('en')}
                  size={14}
                  uppercase>
                  en
                </Text>
              </div>
              {!isMobile && renderSteps()}
              {authState === 'in' ? (
                <>
                  <Dropdown
                    overlay={userDropdownMenu}
                    placement="bottomRight"
                    trigger={['click']}
                    className="desktop-version">
                    <a className="ant-dropdown-link " onClick={(e: any) => e.preventDefault()}>
                      <Icon type="user" />
                    </a>
                  </Dropdown>

                  <Dropdown
                    overlay={userDropdownMenu}
                    placement="bottomRight"
                    trigger={['click']}
                    className="mobile-version">
                    <ul className="hamburger-ul " onClick={(e: any) => e.preventDefault()} trigger={['click']}>
                      <li />
                      <li />
                      <li />
                    </ul>
                  </Dropdown>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        </header>
      )}

      <main className={['/consultor', '/consultor-resultados'].includes(location.pathname) ? 'main-advisor' : 'main'}>
        {authState === 'loading' ? (
          <div className="main-spinner">
            <Spin size="large" />
          </div>
        ) : (
          <div
            className={
              ['/seguro-viagem/desconto10', '/seguro-doencas-graves/voucher10', '/suggestion'].includes(
                location.pathname
              )
                ? ''
                : 'container'
            }>
            <Suspense fallback={<React.Fragment />}>
              <Switch>
                {routes.map((route: Object, index: number) => {
                  return route.authRequired ? (
                    <AuthRoute
                      component={route.component}
                      exact={route.exact}
                      key={index}
                      name={route.name}
                      path={route.path}
                      title={route.title}
                    />
                  ) : (
                    <Route
                      component={route.component}
                      exact={route.exact}
                      key={index}
                      name={route.name}
                      path={route.path}
                      title={route.title}
                    />
                  )
                })}
                <Redirect to="/not-found" />
              </Switch>
            </Suspense>
          </div>
        )}
      </main>

      {/* {location.pathname !== '/iframe-redirect-success' && <HelpCrunch {...user} />} */}
      {/* {location.pathname !== '/iframe-redirect-success' && <Intercom appID={intercomAppId} {...user} />} */}
      {location.pathname != '/iframe-redirect-success' && <NewFooter />}
      {location.pathname !== '/iframe-redirect-success' && (
        <CustomerlyProvider appId={customerlyId}>
          <CustomerlyChat userData={user} />
        </CustomerlyProvider>
      )}
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    login: (payload: Object) => dispatch({ type: actionTypes.LOGIN, payload: payload }),
    logout: () => dispatch({ type: actionTypes.LOGOUT })
  }
}

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(App)
)
