// @flow

import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'

import { CONFIRM_CC_PAYMENT } from '~state/order/mutations'
import ErrorPage from '..'
import { Spin } from 'antd'
import Layout from '~components/layout'
import { RouterHistory } from 'react-router-dom'
import { mapJourneyTypeToPath } from '~utils/journey'
import queryString from 'query-string'
import { store } from 'react-notifications-component'
import { useMutation } from '@apollo/react-hooks'
import { apiErrorHandler } from '~utils/apiErrorHandler'

type PropsType = {
  history: RouterHistory
}
export default function CreditcardRedirect({ history }: PropsType) {
  const [loading, setLoading] = useState(true)
  const { search } = useLocation()
  const [confirmCCOrder] = useMutation(CONFIRM_CC_PAYMENT, {
    onError: apiErrorHandler,
    onCompleted: (data: Object) => {
      // Need this
      if (data && data.confirmCCPayment) {
        const { paymentInfo, uuid, orderType } = data.confirmCCPayment
        const { extra, status } = paymentInfo
        const type = mapJourneyTypeToPath(orderType)
        let redirectUrl = ''

        if (status === 'err' || status === 'error') {
          store.addNotification({
            title: 'OPS!...',
            message:
              'O método de pagamento que escolheste não está a ser aceite pela entidade de pagamentos. Seleciona outro método para finalizares a compra do seguro',
            type: 'danger',
            insert: 'top',
            container: window.innerWidth > 768 ? 'top-right' : 'bottom-center',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 7000
            }
          })
          return history.push(`/${type}/resultados/summary/${uuid}`)
        }

        if (extra && extra.redirect) {
          history.push(`/${type}/compra/beneficiarios/${uuid}`)
        } else {
          history.push(`/${type}/compra/obrigado/${uuid}`)
        }
      }
    }
  })

  useEffect(() => {
    confirmOrder()
  }, [])

  const confirmOrder = () => {
    setLoading(true)
    let params = queryString.parse(search)

    let tkey = !!params.t_key ? params.t_key : params.ep_k1

    //http://localhost:8000/easypay/success?e=59126&r=136000043&v=2000.01&s=ok&k=591261360000431581031722566655&t_key=7ca3ab03-dbda-457b-be01-ce29f17628f7
    //https://staging-app.mudey.pt/seguro-viagem/compra/confirmacao?s=ok0&e=59126&r=512000824&ep_k1=cdf557a8-250d-4c30-af34-e6e78ebb8cad&t=591265120008241581980046472230
    confirmCCOrder({
      variables: {
        status: params.s === 'ok0' ? 'ok' : params.s,
        authorization: params.t,
        value: parseFloat(params.v),
        entity: parseInt(params.e),
        reference: parseInt(params.r),
        tkey: tkey
      }
    }).finally(() => {
      setLoading(false)
    })
  }
  return loading ? <Spin size="large" spinning={loading} /> : <></>
}
