import { gql } from '@apollo/client'



export const LOGIN_USER = gql`
  query userLogin($companyId: String, $managerId: String, $consents: UserConsentsInput) {
    userLogin(input: { companyId: $companyId, managerId: $managerId, consents: $consents}) {
      uuid
      firstName
      lastName
      email
      companyId
      managerId
    }
  }
`

export const getProfessions = gql`
  query {
    getProfessions {
      professions
    }
  }
`

export const GET_PROVIDERS = gql`
  query getProviders($type: String) {
    getProviders(type: $type) {
      insuranceCompany
      name
    }
  }
`

export const VALIDATE_EMAIL_CODE = gql`
  query validateEmail($email: String!) {
    validateEmail(email: $email) {
      isValid
    }
  }
`;

export const VALIDATE_POSTAL_CODE = gql`
  query RootQuery($code: String) {
    getPostalCodeDetails(code: $code) {
      isValid
    }
  }
`;