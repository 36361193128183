// @flow
import React from 'react'
import Cookies from 'js-cookie'
import drFinancas from '~images/icons/partners/doutorFinancas.png'
// import neoFinders from '~images/icons/partners/neofinders.svg'
import mobiziac from '../images/icons/partners/mobiziac.jpg'
import esposeguros from '../images/icons/partners/esposeguros.png'
import pluxee from '../images/icons/partners/pluxee.png'

const PartnersHeader = (props: any) => {
  const { isResultsPage } = props
  const domain = process.env.NODE_ENV === 'development' ? 'localhost' : '.mudey.pt'
  const companyId = Cookies.get('MUDEY_company', { domain: domain })
  switch (companyId && companyId.trim().toLowerCase()) {
    
    case 'mudey':
    default:
      return ''
    case 'doutorfinancas':
      return (
        <div
          className="partner-wrapper"
          style={{
            marginBottom: isResultsPage ? 8 : 0
          }}>
          <p className="partner-text">Em parceria com</p>
          <img src={drFinancas} width="165px" height="auto" alt="" />
        </div>
      )
    // case 'Neofinders':
    //   return (
    //     <div
    //       className="partner-wrapper"
    //       style={{
    //         marginBottom: isResultsPage ? 8 : 0
    //       }}>
    //       <p className="partner-text">Em parceria com</p>
    //       <img src={neoFinders} width="165px" height="auto" alt="" />
    //     </div>
    //   )

    case 'mobizac':
      return (
        <div
          className="partner-wrapper"
          style={{
            marginBottom: isResultsPage ? 8 : 0
          }}>
          <p className="partner-text" style={{ margin: 0 }}>
            Em parceria com
          </p>
          <img src={mobiziac} width="150px" height="70px" alt="" />
        </div>
      )

    case 'esposeguros':
      return (
        <div
          className="partner-wrapper"
          style={{
            marginBottom: isResultsPage ? 8 : 0
          }}>
          <p className="partner-text" style={{ margin: 0 }}>
            Em parceria com
          </p>
          <img src={esposeguros} width="200px" height="70px" alt="" />
        </div>
      )
    case 'sodexo':
      return (
        <div
          className="partner-wrapper"
          style={{
            // marginBottom: isResultsPage ? 8 : 0
          }}>
          <p className="partner-text" style={{ margin: 0 }}>
            Em parceria com
          </p>
          <img src={pluxee} width="140px" height="40px" alt="" />
        </div>
      )
  }
}

export default PartnersHeader
