// @flow

/* 

Payment succeeds
4242 4242 4242 4242

Authentication required
4000 0025 0000 3155

Payment is declined
4000 0000 0000 9995

These test card numbers work with any CVC, 
postal code and future expiry date. 

*/

import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { STRIPE_ORDER_INTENT_PAY } from '~state/stripe'
import { useMutation } from '@apollo/react-hooks'
import { apiErrorHandler } from '~utils/apiErrorHandler'
import type { PayDataType, OrderIntentResponse } from './types'
import logo from '~images/stripe_logo.png'
import './index.less'

const cardStyle = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#32325d'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
}

export const CheckoutForm = ({ payData }: { payData: PayDataType }) => {
  const dispatch = useDispatch()
  const [succeeded, setSucceeded] = useState(false)
  const [error, setError] = useState(null)
  const [processing, setProcessing] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [clientSecret, setClientSecret] = useState('')
  const stripe = useStripe()
  const elements = useElements()
  const buttonText = 'Comprar'

  const [getStripeKeys] = useMutation(STRIPE_ORDER_INTENT_PAY, {
    onCompleted: ({ orderIntentPay: { clientSecret } }: { orderIntentPay: OrderIntentResponse }) => {
      setClientSecret(clientSecret)
    },
    onError: apiErrorHandler
  })

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (payData && payData.uuid) {
      getStripeKeys({ variables: payData })
    }
  }, [])

  const handleChange = async (event: any) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty)
    setError(event.error ? event.error.message : '')
  }

  const handleSubmit = async (ev: any) => {
    ev.preventDefault()
    setProcessing(true)
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: ev.target.name.value
        }
      }
    })
    dispatch({ type: 'STRIPE_PAYMENT_PROGRESS' })
    if (payload.error) {
      setError(`${payload.error.message}`)
      setProcessing(false)
      dispatch({ type: 'STRIPE_PAYMENT_FAIL' })
    } else {
      setError(null)
      setProcessing(false)
      setSucceeded(true)
      dispatch({ type: 'STRIPE_PAYMENT_SUCCESS' })
    }
  }

  return (
    <form className="stripe-payment-form" onSubmit={handleSubmit}>
      <img className="stripe-logo" src={logo} />
      <div className="stripe-inputs">
        <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
        <button className="card-button" disabled={processing || disabled || succeeded} id="submit">
          <span id="button-text">{processing ? <div className="spinner" id="spinner" /> : buttonText}</span>
        </button>
        {/* Show any error that happens when processing the payment */}
        {error && (
          <div className="card-error" role="alert">
            {error}
          </div>
        )}
      </div>
    </form>
  )
}
