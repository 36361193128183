export const extractParams = (params: any, search: any) => {
  let paramsObject = {}
  for (let i = 0; i < params.length; i++) {
    paramsObject[params[i]] = new URLSearchParams(search).get(params[i])
  }
  paramsObject['userId'] = paramsObject.overview
  delete paramsObject.overview

  return paramsObject
}
