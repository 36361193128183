import { applyMiddleware, createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { composeWithDevTools } from 'redux-devtools-extension'

import reducer from './reducer'
import thunk from 'redux-thunk'

const persistConfig = {
  key: 'root',
  storage: storage
}

const pReducer = persistReducer(persistConfig, reducer)

export const store = createStore(pReducer,composeWithDevTools(applyMiddleware(thunk)))

export const persistor = persistStore(store)
