// @flow
import './index.less'

import React, { useState, useEffect } from 'react'
import { Col, Progress, Row, Spin } from 'antd'
import { ProgressInsurers } from '../progress-insurers'
import Button from '../button'
import PartnersHeader from '~utils/partners-header'
import yellowDiscount from '../../images/critical-discount.png'
//not the best solution
import { isMobileHook } from '../../containers/results/_generic/isMobile'

type PropsType = {
  backText?: string,
  children: any,
  hasBack?: boolean,
  loading: boolean,
  onBack?: Function,
  subtitle?: string,
  title: string,
  noInsurers: boolean,
  type: string,
  subtitleAlign: string,
  journeyType: string,
  criticalIllnessJourneyData: any
}

export default function Layout({
  backText,
  children,
  hasBack,
  loading,
  onBack,
  subtitle,
  title,
  noInsurers = false,
  journeyType,
  type = '',
  criticalIllnessJourneyData,
  subtitleAlign = 'left'
}: PropsType) {
  const [counter, setCounter] = useState(0)
  const [showCallMeButton, setShowCallMeButton] = useState(false)
  const [showMoreInfoButton, seteShowMoreInfoButton] = useState(false)

  const callMeTypes = ['DENTAL', 'LIFE', 'CAR', 'HEALTH', 'PERSONALACCIDENT']
  const isMobile = isMobileHook()

  useEffect(() => {
    const showCallMe = callMeTypes.some((t: string) => type === t)

    setShowCallMeButton(showCallMe)

    seteShowMoreInfoButton(!showCallMe && type === 'MORTGAGE')
  }, [type])
  useEffect(() => {
    if (loading) {
      const secondsBar = 60
      counter < 210 && setTimeout(() => setCounter(counter + 1), secondsBar * 10)
    }
  }, [counter, loading])

  useEffect(() => {
    if (!loading) {
      setCounter(0)
    }
  }, [loading])

  const content = (
    <div className="layout">
      <div
        className="layout__header"
        style={{
          gridTemplateColumns:
            !!backText && subtitle
              ? '220px 1fr 0fr'
              : !!backText && !subtitle
              ? '200px 1fr'
              : !backText && subtitle && showCallMeButton && showCallMeButton
              ? '18% 40% 40%'
              : !showCallMeButton && !showCallMeButton && '18% 60%',

          marginBottom: isMobile ? 20 : 8
        }}>
        {isMobile ? <PartnersHeader isResultsPage /> : ''}
        {backText && backText !== '' ? (
          <span className="layout__header-back" onClick={onBack}>
            {backText && `<- ${backText}`}
          </span>
        ) : (
          <span className="layout__header-back" />
        )}
        <h1 className="layout__header-title">{title}</h1>
        

        {!isMobile ? <PartnersHeader isResultsPage /> : ''}
        {showCallMeButton && (
          <Button color="yellow" styles={({ border: 'solid 2px #895c85' }, { marginTop: 10 })} onClick={() => {}}>
            <span
              style={{
                width: 90,
                height: 50,
                fontSize: 20,
                wordBreak: 'break-word',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              Dúvidas?
            </span>
          </Button>
        )}
        {showMoreInfoButton && (
          <Button color="yellow" onClick={() => {}}>
            <span
              style={{
                width: 165,
                height: 50,
                fontSize: 17,
                wordBreak: 'break-word',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              Obter mais informação
            </span>
          </Button>
        )}
      </div>
      {journeyType == 'CRITICAL' && criticalIllnessJourneyData && (
        <div>
          <Row>
            <Col span={24} style={{ marginBottom: 20 }}>
              {' '}
              Podes subscrever este seguro até aos 65 anos (inclusive), e cobre Cancro, Enfarte do Miocárdio e AVC. Tem
              um período de carência de 6 meses. Para{' '}
              {criticalIllnessJourneyData.family === 'JUSTME'
                ? 'Apenas eu'
                : criticalIllnessJourneyData.family === 'MORETHANTWO'
                ? 'Eu e o meu conjuge'
                : ''}
              , temos as seguintes opções
            </Col>
          </Row>
        </div>
      )}
      <div className="layout__content">{children}</div>
    </div>
  )

  return loading ? (
    <>
      <div className="loading-page">
        {!noInsurers && title == 'Resultados' && counter > 7 && counter < 210 ? (
          <div className="loading-progress-content">
            <ProgressInsurers />
            <Progress
              percent={counter / 1.8}
              showInfo={false}
              status="active"
              strokeColor={{
                from: '#aacecd',
                to: '#895d85'
              }}
              width={250}
            />
            <h1 className="loading-text">Estamos a receber preços. Aguarda um pouco</h1>
          </div>
        ) : (
          <Spin size="large" />
        )}
      </div>
      {content}
    </>
  ) : (
    content
  )
}

Layout.defaultProps = {
  hasBack: false,
  backText: '',
  subtitle: '',
  onBack: null
}
