import { gql } from '@apollo/client'

// OrderConsentInput
// -----------------
// residentInPortugal: Boolean
// privacyPolicy: Boolean
// termsAndConditions: Boolean

// PaymentPeriodInput
// ------------------
//description: String
//type: String
//value: Float

// PaymentType
// -----------
// CREDITCARD
// DIRECTDEBIT
// MBREFERENCE
// MBWAY

export const STRIPE_ORDER_INTENT_PAY = gql`
  mutation orderIntentPay(
    $uuid: ID!
    $consents: OrderConsentInput!
    $email: String!
    $name: String!
    $paymentPeriod: PaymentPeriodInput!
    $paymentType: PaymentType!
    $iban: String
  ) {
    orderIntentPay(
      input: {
        uuid: $uuid
        consents: $consents
        email: $email
        name: $name
        paymentPeriod: $paymentPeriod
        paymentType: $paymentType
        iban: $iban
      }
    ) {
      clientSecret
      publicKey
    }
  }
`
