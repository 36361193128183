import React, { useState, useEffect } from 'react'

let resizeTimer = null
const MOBILE_BREAKPOINT = 820

export const isMobileHook = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_BREAKPOINT)

  const handleResize = () => {
    clearTimeout(resizeTimer)
    resizeTimer = setTimeout(() => {
      setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT)
    }, 500)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return isMobile
}
