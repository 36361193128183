// @flow
import { useState, useEffect } from 'react'

const titles = {
  'seguro-vida': {
    simulacao: 'MUDEY | Seguro Vida | Questionário',
    resultados: {
      summary: 'MUDEY | Seguro Vida | Pagamento',
      comparar: 'MUDEY | Seguro Vida | Comparação',
      pacotes: 'MUDEY | Seguro Vida | Resultados'
    },
    compra: {
      pagamento: 'MUDEY | Seguro Vida| Pagamento',
      obrigado: 'MUDEY | Seguro Vida| Pagamento',
      beneficiarios: '',
      'thank-you': 'MUDEY | Seguro Vida | Pagamento',
      'payment-failure': '',
      'confirmacao-compra': ''
    }
  },

  'seguro-vida-credito': {
    simulacao: 'MUDEY | Seguro Vida Crédito | Questionário',
    resultados: {
      summary: 'MUDEY | Seguro Vida Crédito | Pagamento',
      comparar: 'MUDEY | Seguro Vida Crédito | Comparação',
      pacotes: 'MUDEY | Seguro Vida Crédito | Resultados'
    },
    compra: {
      pagamento: 'MUDEY | Seguro Vida Crédito | Pagamento',
      obrigado: 'MUDEY | Seguro Vida Crédito | Pagamento',
      beneficiarios: '',
      'thank-you': 'MUDEY | Seguro Vida Crédito | Pagamento',
      'payment-failure': '',
      'confirmacao-compra': ''
    },
    'calculadora-impacto-spread': {
      questionario: 'MUDEY | Seguro Vida Crédito | Calculadora - Questionário',
      resultados: 'MUDEY | Seguro Vida Crédito | Calculadora - Resultados'
    }
  },

  'seguro-viagem': {
    simulacao: 'MUDEY | Seguro Viagem | Questionário',
    resultados: {
      summary: 'MUDEY | Seguro Viagem | Pagamento',
      comparar: 'MUDEY | Seguro Viagem | Comparação',
      pacotes: 'MUDEY | Seguro Viagem | Resultados'
    },
    compra: {
      pagamento: 'MUDEY | Seguro Viagem | Comparação',
      obrigado: 'MUDEY | Seguro Viagem | Comparação',
      beneficiarios: '',
      'thank-you': 'MUDEY | Seguro Viagem | Comparação',
      'payment-failure': '',
      'confirmacao-compra': ''
    }
  },

  'seguro-garantia': {
    simulacao: 'MUDEY | Seguro Garantia | Questionário',
    resultados: {
      summary: 'MUDEY | Seguro Garantia | Pagamento',
      comparar: 'MUDEY | Seguro Garantia | Comparação',
      pacotes: 'MUDEY | Seguro Garantia | Resultados'
    },
    compra: {
      pagamento: 'MUDEY | Seguro Garantia | Comparação',
      obrigado: 'MUDEY | Seguro Garantia | Comparação',
      beneficiarios: '',
      'thank-you': 'MUDEY | Seguro Garantia | Comparação',
      'payment-failure': '',
      'confirmacao-compra': ''
    }
  },

  'seguro-ppr': {
    simulacao: 'MUDEY | Seguro PPR | Questionário',
    resultados: {
      pacotes: 'MUDEY | Seguro PPR | Resultados',
      summary: 'MUDEY | Seguro PPR | Pagamento',
      obrigado: 'MUDEY | Seguro PPR | Comparação',
      'informação-adicional': 'MUDEY Empresas | Seguro de Vida Empresas | Assinatura'
    },
    compra: {
      pagamento: 'MUDEY | Seguro PPR | Pagamento',
      obrigado: 'MUDEY | Seguro PPR | Pagamento',
      beneficiarios: '',
      'thank-you': 'MUDEY | Seguro PPR | Compra',
      'payment-failure': '',
      'confirmacao-compra': ''
    }
  },
  'seguro-acidentes-pessoais': {
    simulacao: 'MUDEY | Acidentes Pessoais | Questionário',
    resultados: {
      summary: 'MUDEY | Acidentes Pessoais | Pagamento',
      comparar: 'MUDEY | Acidentes Pessoais | Comparação',
      pacotes: 'MUDEY | Acidentes Pessoais | Resultados'
    },
    compra: {
      pagamento: 'MUDEY | Acidentes Pessoais | Pagamento',
      obrigado: 'MUDEY | Acidentes Pessoais | Pagamento',
      beneficiarios: '',
      'thank-you': 'MUDEY | Acidentes Pessoais | Pagamento',
      'payment-failure': '',
      'confirmacao-compra': ''
    }
  },

  'seguro-bicicleta': {
    simulacao: 'MUDEY | Seguro de Bicicleta | Questionário',
    resultados: {
      summary: 'MUDEY | Seguro de Bicicleta | Pagamento',
      comparar: 'MUDEY | Seguro de Bicicleta | Comparação',
      pacotes: 'MUDEY | Seguro de Bicicleta | Resultados'
    },
    compra: {
      pagamento: 'MUDEY | Seguro de Bicicleta | Pagamento',
      obrigado: 'MUDEY | Seguro de Bicicleta | Pagamento',
      beneficiarios: '',
      'thank-you': 'MUDEY | Seguro de Bicicleta | Pagamento',
      'payment-failure': '',
      'confirmacao-compra': ''
    }
  },

  'seguro-crianca': {
    simulacao: 'MUDEY | Seguro Сriança | Questionário',
    resultados: {
      summary: 'MUDEY | Seguro Сriança | Pagamento',
      comparar: 'MUDEY | Seguro Сriança | Comparação',
      pacotes: 'MUDEY | Seguro Сriança | Resultados'
    },
    compra: {
      pagamento: 'MUDEY | Seguro Сriança | Pagamento',
      obrigado: 'MUDEY | Seguro Сriança | Pagamento',
      beneficiarios: '',
      'thank-you': 'MUDEY | Seguro Сriança | Pagamento',
      'payment-failure': '',
      'confirmacao-compra': ''
    }
  },

  'seguro-saude': {
    simulacao: 'MUDEY | Seguro Saúde | Questionário',
    resultados: {
      summary: 'MUDEY | Seguro Saúde | Pagamento',
      comparar: 'MUDEY | Seguro Saúde | Comparação',
      pacotes: 'MUDEY | Seguro Saúde | Resultados'
    },
    compra: {
      pagamento: 'MUDEY | Seguro Saúde | Pagamento',
      obrigado: 'MUDEY | Seguro Saúde | Pagamento',
      beneficiarios: '',
      'thank-you': 'MUDEY | Seguro Saúde | Pagamento',
      'payment-failure': '',
      'confirmacao-compra': ''
    }
  },

  'seguro-dental': {
    simulacao: 'MUDEY | Seguro Dentário | Questionário',
    resultados: {
      summary: 'MUDEY | Seguro Dentário | Pagamento',
      comparar: 'MUDEY | Seguro Dentário | Comparação',
      pacotes: 'MUDEY | Seguro Dentário | Resultados'
    },
    compra: {
      pagamento: 'MUDEY | Seguro Dentário | Pagamento',
      obrigado: 'MUDEY | Seguro Dentário | Pagamento',
      beneficiarios: '',
      'thank-you': 'MUDEY | Seguro Dentário | Pagamento',
      'payment-failure': '',
      'confirmacao-compra': ''
    }
  },

  'seguro-empregada': {
    simulacao: 'MUDEY | Seguro Empregada Doméstica | Questionário',
    resultados: {
      summary: 'MUDEY | Seguro Empregada Doméstica | Pagamento',
      comparar: 'MUDEY | Seguro Empregada Doméstica | Comparação',
      pacotes: 'MUDEY | Seguro Empregada Doméstica | Resultados'
    },
    compra: {
      pagamento: 'MUDEY | Seguro Empregada Doméstica | Pagamento',
      obrigado: 'MUDEY | Seguro Empregada Doméstica | Obrigado',
      beneficiarios: '',
      'thank-you': 'MUDEY | Seguro Empregada Doméstica | Pagamento',
      'payment-failure': '',
      'confirmacao-compra': ''
    }
  },

  'seguro-carro': {
    simulacao: 'MUDEY | Seguro Carro | Questionário',
    resultados: {
      summary: 'MUDEY | Seguro Carro | Pagamento',
      comparar: 'MUDEY | Seguro Carro | Comparação',
      pacotes: 'MUDEY | Seguro Carro | Resultados'
    },
    compra: {
      pagamento: 'MUDEY | Seguro Carro | Pagamento',
      obrigado: 'MUDEY | Seguro Carro | Pagamento',
      beneficiarios: '',
      'thank-you': 'MUDEY | Seguro Carro | Pagamento',
      'payment-failure': '',
      'confirmacao-compra': ''
    }
  },

  'seguro-trotinete': {
    simulacao: 'MUDEY | Seguro Trotinete | Questionário',
    resultados: {
      summary: 'MUDEY | Seguro Trotinete | Pagamento',
      comparar: 'MUDEY | Seguro Trotinete | Comparação',
      pacotes: 'MUDEY | Seguro Trotinete | Resultados'
    },
    compra: {
      pagamento: 'MUDEY | Seguro Trotinete | Pagamento',
      obrigado: 'MUDEY | Seguro Trotinete | Pagamento',
      beneficiarios: '',
      'thank-you': 'MUDEY | Seguro Trotinete | Pagamento',
      'payment-failure': '',
      'confirmacao-compra': ''
    }
  },

  'seguro-casa': {
    simulacao: 'MUDEY | Seguro Casa | Questionário',
    resultados: {
      summary: 'MUDEY | Seguro Casa | Pagamento',
      comparar: 'MUDEY | Seguro Casa | Comparação',
      pacotes: 'MUDEY | Seguro Casa | Resultados'
    },
    compra: {
      pagamento: 'MUDEY | Seguro Casa | Pagamento',
      obrigado: 'MUDEY | Seguro Casa | Pagamento',
      beneficiarios: '',
      'thank-you': 'MUDEY | Seguro Casa | Pagamento',
      'payment-failure': '',
      'confirmacao-compra': ''
    }
  },

  'doencas-graves': {
    simulacao: 'MUDEY | Doenças Graves | Questionário',
    resultados: {
      summary: 'MUDEY | Doenças Graves | Pagamento',
      pacotes: 'MUDEY | Doenças Graves | Resultados'
    },
    compra: {
      pagamento: 'MUDEY | Doenças Graves | Pagamento',
      obrigado: 'MUDEY | Doenças Graves | Pagamento',
      beneficiarios: '',
      'thank-you': 'MUDEY | Doenças Graves | Pagamento',
      'payment-failure': '',
      'confirmacao-compra': ''
    }
  },

  hospitalização: {
    simulacao: 'MUDEY | Hospitalização | Questionário',
    resultados: {
      summary: 'MUDEY | Hospitalização | Pagamento',
      pacotes: 'MUDEY | Hospitalização | Resultados'
    },
    compra: {
      pagamento: 'MUDEY | Hospitalização | Pagamento',
      obrigado: 'MUDEY | Hospitalização | Pagamento',
      beneficiarios: '',
      'thank-you': 'MUDEY | Hospitalização | Pagamento',
      'payment-failure': '',
      'confirmacao-compra': ''
    }
  },
  'seguro-acidentes-trabalho-independente': {
    simulacao: 'MUDEY | Seguro AT | Questionário',
    resultados: {
      summary: 'MUDEY | Seguro AT | Pagamento',
      comparar: 'MUDEY | Seguro AT | Comparação',
      pacotes: 'MUDEY | Seguro AT | Resultados'
    },
    compra: {
      pagamento: 'MUDEY | Seguro AT | Pagamento',
      obrigado: 'MUDEY | Seguro AT | Pagamento',
      beneficiarios: '',
      'thank-you': 'MUDEY | Seguro AT | Pagamento',
      'payment-failure': '',
      'confirmacao-compra': ''
    }
  },
  'seguro-animais-domesticos': {
    simulacao: 'MUDEY | Seguro Animais Domésticos | Questionário',
    resultados: {
      summary: 'MUDEY | Seguro Animais Domésticos | Pagamento',
      comparar: 'MUDEY | Seguro Animais Domésticos | Comparação',
      pacotes: 'MUDEY | Seguro Animais Domésticos | Resultados'
    },
    compra: {
      pagamento: 'MUDEY | Seguro Animais Domésticos | Pagamento',
      obrigado: 'MUDEY | Seguro Animais Domésticos | Pagamento',
      beneficiarios: '',
      'thank-you': 'MUDEY | Seguro Animais Domésticos | Pagamento',
      'payment-failure': '',
      'confirmacao-compra': ''
    }
  },
  'seguro-alojamento-local': {
    simulacao: 'MUDEY | Seguro Alojamento Local | Questionário',
    resultados: {
      summary: 'MUDEY | Seguro Alojamento Local | Pagamento',
      comparar: 'MUDEY | Seguro Alojamento Local | Comparação',
      pacotes: 'MUDEY | Seguro Alojamento Local | Resultados'
    },
    compra: {
      pagamento: 'MUDEY | Seguro Alojamento Local | Pagamento',
      obrigado: 'MUDEY | Seguro Alojamento Local | Pagamento',
      beneficiarios: '',
      'thank-you': 'MUDEY | Seguro Alojamento Local | Pagamento',
      'payment-failure': '',
      'confirmacao-compra': ''
    }
  },
  'iframe-redirect-success': 'MUDEY Empresa | Seguro Vida Empresas | Documento Assinado'
}

export const titleManager = (location: any): void => {
  if (location && location.pathname) {
    const root = location.pathname.split('/')[1] || ''
    const firstKey = location.pathname.split('/')[2] || ''
    const lastKey = location.pathname.split('/')[3] || ''
    const title = () => {
      if (location.pathname == '/seguro-viagem/desconto10') {
        return 'Promocão - Seguro de Viagem'
      }
      if (location.pathname == '/seguro-doencas-graves/voucher10') {
        return 'Promocão - Doenças Graves'
      }
      if (location.pathname == '/consultor-questionario') return 'MUDEY | Consultor | Questionário'
      if (location.pathname == '/consultor-resultados') return 'MUDEY | Consultor | Resultados'

      if (lastKey) {
        return titles[root][firstKey][lastKey] || null
      }
      if (!lastKey && firstKey) {
        return titles[root][firstKey] || null
      }
      return null
    }

    const docTitle = title()

    if (docTitle) {
      document.title = docTitle
    } else {
      document.title = 'MUDEY'
    }
  } else {
    document.title = 'MUDEY'
  }
}
