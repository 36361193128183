// Auth Routes (Login required)
import CreditcardRedirect from '../containers/state-page/redirect-cc'
import React from 'react'

// Public Routes
const Login = React.lazy(() => import('../containers/user/login'))
const Register = React.lazy(() => import('../containers/user/register'))
const Forgot = React.lazy(() => import('../containers/user/forgot'))
const Recovery = React.lazy(() => import('../containers/user/recovery'))
const Home = React.lazy(() => import('../containers/home'))
const CriticalJourney = React.lazy(() => import('../containers/journeys/critical-v2/CriticalJourney'))
const Hospitalization = React.lazy(() => import('../containers/journeys/hospitalization'))
const PPRJourneyNew = React.lazy(() => import('../containers/journeys/ppr-v2/PprJourney'))
const Compare = React.lazy(() => import('../containers/compare'))
const ComparePersonalAccidents = React.lazy(() => import('../containers/compare-personal-accidents'))
const CompareLIFE = React.lazy(() => import('../containers/compare_new/compare-life-new'))
const CompareBike = React.lazy(() => import('../containers/compare-bike'))
const CompareHealth = React.lazy(() => import('../containers/compare_new/compare-health-new'))
const CompareChild = React.lazy(() => import('../containers/compare-child'))
const CompareDental = React.lazy(() => import('../containers/compare-dental'))
const CompareCar = React.lazy(() => import('../containers/compare_new/compare-car-new'))

const CompareScooter = React.lazy(() => import('../containers/compare-scooter'))
const CompareHome = React.lazy(() => import('../containers/compare-home'))

const TravelJourney = React.lazy(() => import('../containers/journeys/travel-v2/TravelJourney'))
const MortgageJourney = React.lazy(() => import('../containers/journeys/mortgage-v2/MortagageJourney'))

const TravelPackages = React.lazy(() => import('../containers/results/travel_new/results'))
const PersonalAccidentsPackage = React.lazy(() => import('../containers/results/personal-accidents'))
const LIFEPackage = React.lazy(() => import('../containers/results/life-insurance-new/index'))
const HealthInsuranceResults = React.lazy(() => import('../containers/results/health-v2'))
const HousekeeperInsuranceResults = React.lazy(() => import('../containers/results/housekeeper/results'))
const DentalInsuranceResults = React.lazy(() => import('../containers/results/dental'))
const CarResults = React.lazy(() => import('../containers/results/car_new/results'))
const HomePackage = React.lazy(() => import('../containers/results/home'))
const MortgageResults = React.lazy(() => import('../containers/results/mortgage_new'))
const CriticalResults = React.lazy(() => import('~containers/results/critical/index'))
const HospitalizationResults = React.lazy(() => import('../containers/results/hospitalization'))
const PPRResults = React.lazy(() => import('../containers/results/ppr-new'))
const WorkResults = React.lazy(() => import('../containers/results/workAccidentResult/results'))
const PetInsuranceResult = React.lazy(() => import('../containers/results/pet-insurance/index'))
const LocalLodgingResult = React.lazy(() => import('../containers/results/localLodging/results'))

const TravelSummary = React.lazy(() => import('../containers/summary/travel-v2/TravelSummary'))
const TravelCompare = React.lazy(() => import('../containers/compare_new/compare-travel-new'))
const LIFESummary = React.lazy(() => import('../containers/summary/life-v2/LifeSummary'))
const BIKESummary = React.lazy(() => import('../containers/summary/bike'))
const CHILDSummary = React.lazy(() => import('../containers/summary/child'))
const SCOOTERSummary = React.lazy(() => import('../containers/summary/scooter'))
const PersonalAccidentSummary = React.lazy(() => import('../containers/summary/personal-accidents'))
const HealthInsuranceSummary = React.lazy(() => import('../containers/summary/health-v2'))
const HouseKeeperInsuranceSummary = React.lazy(() => import('../containers/summary/housekeeper-v2/HouseKeeperSummary'))
const DentalInsuranceSummary = React.lazy(() => import('../containers/summary/dental'))
// const CarSummary = React.lazy(() => import('../containers/summary/car'))
const CarSummary = React.lazy(() => import('../containers/summary/car-new'))
const HomeSummary = React.lazy(() => import('../containers/summary/home'))
const MortgageSummary = React.lazy(() => import('../containers/summary/mortgage-v2/MortgageSummary'))
const CriticalSummary = React.lazy(() => import('../containers/summary/critical-v2/CriticalSummary'))
const HospitalizationSummary = React.lazy(() => import('../containers/summary/hospitalization'))
const PPRSummary = React.lazy(() => import('../containers/summary/ppr-new'))
const WorkSummary = React.lazy(() => import('~containers/summary/work-accident-v2'))
const PetSummary = React.lazy(() => import('../containers/summary/pet-insurance/index'))
const LocalLodgingSummary = React.lazy(() => import('../containers/summary/localLodging/index'))

const ThankYouTravel = React.lazy(() => import('../containers/thank-you/travel'))
const ThankYouAccident = React.lazy(() => import('../containers/thank-you/personal-accidents'))
const ThankYouLife = React.lazy(() => import('../containers/thank-you/life'))
const ThankYouBike = React.lazy(() => import('../containers/thank-you/bike'))
const ThankYouCritical = React.lazy(() => import('../containers/thank-you/critical'))
const ThankYouHospitalization = React.lazy(() => import('../containers/thank-you/hospitalization'))
const ThankYouHealth = React.lazy(() => import('../containers/thank-you/health-v2'))
const ThankYouHouseKeeper = React.lazy(() => import('../containers/thank-you/housekeeper'))
const ThankYouChild = React.lazy(() => import('../containers/thank-you/child'))
const ThankYouDental = React.lazy(() => import('../containers/thank-you/dental'))
const ThankYouCar = React.lazy(() => import('../containers/thank-you/car'))
const ThankYouScooter = React.lazy(() => import('../containers/thank-you/scooter'))
const ThankYouHome = React.lazy(() => import('../containers/thank-you/home'))
const ThankYouMortgage = React.lazy(() => import('../containers/thank-you/mortgage'))
const ThankYouPPR = React.lazy(() => import('../containers/thank-you/PPR'))
const ThankYouWork = React.lazy(() => import('../containers/thank-you/workAccident'))
const LandingPageEdenred = React.lazy(() => import('../containers/LandingPages/landing-page-travel-edenred/landing'))
const LandingPageCritical = React.lazy(() => import('../containers/LandingPages/landing-page-critical-edenred/landing'))
const ThankYouPet = React.lazy(() => import('../containers/thank-you/pet-insurance'))
const ThankYouLocalLodging = React.lazy(() => import('../containers/thank-you/localLodging'))

const PaymentFailure = React.lazy(() => import('../containers/payment-failure'))

const LifeJourney = React.lazy(() => import('../containers/journeys/life-v2/LifeJourney'))
const CarJourney = React.lazy(() => import('../containers/journeys/Car_new'))
const WorkAccident = React.lazy(() => import('../containers/journeys/work-accident-v2/WorkAccidentJourney'))
const PersonalAccident = React.lazy(() => import('../containers/journeys/personal-accidents'))
const PersonalExtra = React.lazy(() => import('../containers/journeys/personal-accidents/extra'))
// const LifeExtra = React.lazy(() => import('../containers/journeys/life/extra'))
const HealthJourney = React.lazy(() => import('../containers/journeys/health-v2/index'))
const HouseKeeperJourney = React.lazy(() => import('../containers/journeys/housekeeper-v2/HouseKeeperJourney'))
const DentalJourney = React.lazy(() => import('../containers/journeys/dental'))
const PetInsuranceJourney = React.lazy(() => import('../containers/journeys/pet-v2/PetJourney'))
const LocalLodgingJourney = React.lazy(() => import('../containers/journeys/local-lodging-v2/LocalLodingJourney'))

const NotFoundPage = React.lazy(() => import('../containers/state-page/not-found'))
const BicycleInsurance = React.lazy(() => import('../containers/results/bicycle-insurance'))
const ChildInsurance = React.lazy(() => import('../containers/results/child-insurance'))
const ScooterInsurance = React.lazy(() => import('../containers/results/scooter-insurance'))

const HealthQuestionnarie = React.lazy(() => import('../containers/health-questionnarie'))
const Payment = React.lazy(() => import('../containers/payment'))
const HomeJourney = React.lazy(() => import('../containers/journeys/home'))

const PetVerDetalhes = React.lazy(() => import('../containers/results/pet-insurance/results'))
const PPRConfirmation = React.lazy(() =>
  import('../containers/summary/ppr-new/components/confirmation/pprConfirmation')
)

const IframeRedirect = React.lazy(() => import('../containers/iframeRedirect/index'))
const Advisor = React.lazy(() => import('../containers/advisor'))
const AdvisorSuggestion = React.lazy(() => import('../containers/advisor/suggestion/table/AdviosorSuggestionTable'))
const AdvisorForms = React.lazy(() => import('../containers/advisor/forms'))
const MortgageCalculator = React.lazy(() => import('../containers/mortgage-calculator-external'))
const MortgageCalculatorResults = React.lazy(() => import('../containers/mortgage-calculator-results'))
//kk
const KKJourney=React.lazy(()=>import('../containers/journeys/kk'))
const KKResult=React.lazy(()=>import('../containers/results/kk'))
const KKSummary=React.lazy(()=>import('../containers/summary/kk'))
const ThankYouKK=React.lazy(()=>import('../containers/thank-you/kk'))
const PaymentCreditKK=React.lazy(()=>import('../containers/payment/kk'))
// const PaymentCredit = React.lazy(() => import("../containers/payment/kk"));



const routes = [
  {
    path: '/',
    exact: true,
    name: 'Home',
    component: Home,
    authRequired: false
  },
  {
    path: '/not-found',
    exact: true,
    name: 'NotFoundPage',
    component: NotFoundPage,
    authRequired: false
  },
  {
    path: '/login',
    exact: true,
    name: 'Login',
    component: Login,
    authRequired: false
  },
  {
    path: '/register',
    exact: true,
    name: 'Register',
    component: Register,
    authRequired: false
  },
  {
    path: '/forgot',
    exact: true,
    name: 'Forgot',
    component: Forgot,
    authRequired: false
  },
  {
    path: '/restore',
    exact: true,
    name: 'Recovery',
    component: Recovery,
    authRequired: false
  },
  // {
  //   path: '/seguro-viagem/resultados/comparar/:journeyId?',
  //   exact: true,
  //   name: 'Compare',
  //   component: Compare,
  //   authRequired: true
  // },
  {
    path: '/seguro-viagem/resultados/comparar/:journeyId?',
    exact: true,
    name: 'TravelCompare',
    component: TravelCompare,
    authRequired: false
  },
  {
    // path: '/doencas-graves/simulacao/:journeyId?',
    path: '/doencas-graves/simulacao/',
    exact: true,
    name: 'CriticalJourney',
    component: CriticalJourney,
    authRequired: false
  },
  {
    path: '/doencas-graves/resultados/pacotes/:journeyId?',
    // path: '/doencas-graves/resultados/pacotes/',
    exact: true,
    name: 'CriticalResults',
    component: CriticalResults,
    authRequired: false
  },
  {
    path: '/doencas-graves/resultados/summary/:orderId?',
    exact: true,
    name: 'CriticalSummary',
    component: CriticalSummary,
    authRequired: false
  },
  {
    path: '/doencas-graves/compra/obrigado/:orderId',
    exact: true,
    name: 'ThankYouCritical',
    component: ThankYouCritical,
    authRequired: false
  },
  {
    path: '/seguro-ppr/resultados/summary/:orderId',
    exact: true,
    name: 'PPRSummary',
    component: PPRSummary,
    authRequired: false
  },
  {
    path: '/seguro-acidentes-pessoais/resultados/comparar/:journeyId?',
    exact: true,
    name: 'ComparePersonalAccidents',
    component: ComparePersonalAccidents,
    authRequired: true
  },
  {
    path: '/seguro-ppr/resultados/pacotes/:journeyId?',
    exact: true,
    name: 'PPRResults',
    component: PPRResults,
    authRequired: false
  },

  {
    path: '/seguro-viagem/simulacao/:journeyId?',
    exact: true,
    name: 'TravelJourney',
    component: TravelJourney,
    authRequired: false
    //title: `MUDEY | Seguro de Viagem| Questionário`
  },
  {
    path: '/seguro-vida/simulacao/',
    exact: true,
    name: 'LifeJourney',
    component: LifeJourney,
    authRequired: false
    //title: `MUDEY | Seguro de Vida Proteção | Questionário`
  },
  {
    path: '/seguro-vida/resultados/pacotes/:journeyId?',
    exact: true,
    name: 'LIFEPackage',
    component: LIFEPackage,
    authRequired: false
  },
  {
    path: '/seguro-vida/resultados/comparar/:journeyId?',
    exact: true,
    name: 'CompareLIFE',
    component: CompareLIFE,
    authRequired: false
  },
  {
    path: '/seguro-vida/resultados/summary/:orderId?',
    exact: true,
    name: 'LIFESummary',
    component: LIFESummary,
    authRequired: false
  },
  {
    path: '/seguro-acidentes-pessoais/simulacao/:journeyId?',
    exact: true,
    name: 'PersonalAccident',
    component: PersonalAccident,
    authRequired: true,
    title: `MUDEY | Acidentes Pessoais| Questionário`
  },
  {
    path: '/seguro-viagem/resultados/pacotes/:journeyId?',

    exact: true,
    name: 'TravelPackages',
    component: TravelPackages,
    authRequired: false
  },
  {
    path: '/seguro-viagem/resultados/summary/:orderId?',
    exact: true,
    name: 'TravelSummary',
    component: TravelSummary,
    authRequired: false
  },

  {
    path: '/seguro-acidentes-pessoais/resultados/summary/:orderId?',
    exact: true,
    name: 'PersonalAccidentSummary',
    component: PersonalAccidentSummary,
    authRequired: true
  },
  {
    path: '/seguro-viagem/compra/pagamento/:paymentType',
    exact: true,
    name: 'Payment',
    component: Payment,
    authRequired: true
  },
  {
    path: '/seguro-acidentes-pessoais/compra/obrigado/:orderId',
    exact: true,
    name: 'ThankYouAccident',
    component: ThankYouAccident,
    authRequired: true
  },
  {
    path: '/seguro-vida/compra/obrigado/:orderId',
    exact: true,
    name: 'ThankYouLife',
    component: ThankYouLife,
    authRequired: false
  },
  {
    path: '/seguro-viagem/compra/obrigado/:orderId',
    exact: true,
    name: 'ThankYou',
    component: ThankYouTravel,
    authRequired: false
  },
  {
    path: '/seguro-viagem/compra/payment-failure',
    exact: true,
    name: 'PaymentFailure',
    component: PaymentFailure,
    authRequired: true
  },

  {
    path: '/seguro-acidentes-pessoais/resultados/pacotes/:journeyId?',
    exact: true,
    name: 'PersonalAccidentsPackage',
    component: PersonalAccidentsPackage,
    authRequired: true
  },
  {
    authRequired: true,
    path: '/seguro-acidentes-pessoais/compra/beneficiarios/:orderId',
    exact: true,
    name: 'PersonalExtra',
    component: PersonalExtra
  },
  // {
  //   authRequired: true,
  //   path: '/seguro-vida/compra/beneficiarios/:orderId',
  //   exact: true,
  //   name: 'LifeExtra',
  //   component: LifeExtra
  // },
  {
    authRequired: true,
    path: '/compra/confirmacao-compra',
    exact: true,
    name: 'CreditcardRedirect',
    component: CreditcardRedirect
  },
  {
    authRequired: false,
    path: ['/seguro-bicicleta/resultados/pacotes/motor=com', '/seguro-bicicleta/resultados/pacotes/motor=sem'],
    exact: true,
    name: 'BicycleInsurance',
    component: BicycleInsurance
  },
  {
    authRequired: false,
    path: '/seguro-crianca/resultados/pacotes',
    exact: true,
    name: 'ChildInsurance',
    component: ChildInsurance
  },

  {
    authRequired: false,
    path: '/seguro-bicicleta/resultados/summary/:orderId',
    exact: true,
    name: 'BIKESummary',
    component: BIKESummary
  },
  {
    authRequired: false,
    path: '/seguro-crianca/resultados/summary/:orderId',
    exact: true,
    name: 'CHILDSummary',
    component: CHILDSummary
  },
  {
    authRequired: false,
    path: '/seguro-bicicleta/compra/obrigado/:orderId',
    exact: true,
    name: 'ThankYouBike',
    component: ThankYouBike
  },
  {
    authRequired: false,
    path: '/seguro-crianca/compra/obrigado/:orderId',
    exact: true,
    name: 'ThankYouChild',
    component: ThankYouChild
  },
  {
    path: '/seguro-bicicleta/resultados/comparar/:journeyId?',
    exact: true,
    name: 'CompareBike',
    component: CompareBike,
    authRequired: false
  },
  {
    path: '/seguro-crianca/resultados/comparar/:journeyId?',
    exact: true,
    name: 'CompareChild',
    component: CompareChild,
    authRequired: false
  },
  {
    path: '/health-questionnaire/:policyId',
    exact: true,
    name: 'HealthQuestionnarie',
    component: HealthQuestionnarie,
    authRequired: false // change to true !!!!!
  },
  {
    path: '/seguro-saude/simulacao/:journeyId?',
    exact: true,
    name: 'HealthJourney',
    component: HealthJourney,
    authRequired: false
  },
  {
    path: '/seguro-saude/resultados/pacotes/:journeyId?',
    exact: true,
    name: 'HealthInsuranceResults',
    component: HealthInsuranceResults,
    authRequired: false
  },
  {
    path: '/seguro-saude/resultados/comparar/:journeyId?',
    exact: true,
    name: 'CompareHealth',
    component: CompareHealth,
    authRequired: false
  },
  {
    path: '/seguro-saude/resultados/summary/:orderId',
    exact: true,
    name: 'HealthInsuranceSummary',
    component: HealthInsuranceSummary,
    authRequired: false
  },
  {
    path: '/seguro-saude/compra/obrigado/:orderId',
    exact: true,
    name: 'ThankYouHealth',
    component: ThankYouHealth,
    authRequired: false
  },
  {
    path: '/seguro-dental/simulacao/:journeyId?',
    exact: true,
    name: 'DentalJourney',
    component: DentalJourney,
    authRequired: true
  },
  {
    path: '/seguro-dental/resultados/pacotes/:journeyId?',
    exact: true,
    name: 'DentalInsuranceResults',
    component: DentalInsuranceResults,
    authRequired: true
  },
  {
    path: '/seguro-dental/resultados/comparar/:journeyId?',
    exact: true,
    name: 'CompareDental',
    component: CompareDental,
    authRequired: true
  },
  {
    path: '/seguro-dental/resultados/summary/:orderId',
    exact: true,
    name: 'DentalInsuranceSummary',
    component: DentalInsuranceSummary,
    authRequired: true
  },
  {
    path: '/seguro-dental/compra/obrigado/:orderId',
    exact: true,
    name: 'ThankYouDental',
    component: ThankYouDental,
    authRequired: true
  },
  {
    path: '/seguro-empregada/simulacao/:journeyId?',
    exact: true,
    name: 'HouseKeeperJourney',
    component: HouseKeeperJourney,
    authRequired: false
  },
  {
    path: '/seguro-empregada/resultados/pacotes/:journeyId?',
    exact: true,
    name: 'HousekeeperInsuranceResults',
    component: HousekeeperInsuranceResults,
    authRequired: false
  },
  {
    path: '/seguro-empregada/resultados/summary/:orderId',
    exact: true,
    name: 'HouseKeeperInsuranceSummary',
    component: HouseKeeperInsuranceSummary,
    authRequired: false
  },
  {
    path: '/seguro-empregada/compra/obrigado/:orderId',
    exact: true,
    name: 'ThankYouHouseKeeper',
    component: ThankYouHouseKeeper,
    authRequired: false
  },
  {
    path: '/seguro-carro/simulacao/',
    exact: true,
    name: 'CarJourney',
    component: CarJourney,
    authRequired: false
  },
  {
    path: '/seguro-carro/resultados/pacotes/:journeyId?',
    exact: true,
    name: 'CarResults',
    component: CarResults,
    authRequired: false
  },
  {
    path: '/seguro-carro/resultados/comparar/:journeyId?',
    exact: true,
    name: 'CompareCar',
    component: CompareCar,
    authRequired: false
  },
  {
    path: '/seguro-carro/resultados/summary/:journeyId?',
    // path:'/seguro-carro/resultados/summary',
    exact: true,
    name: 'CarSummary',
    component: CarSummary,
    authRequired: false
  },
  {
    path: '/seguro-carro/compra/obrigado/:journeyId',
    exact: true,
    name: 'ThankYouCar',
    component: ThankYouCar,
    authRequired: false
  },

  {
    authRequired: false,
    path: '/seguro-trotinete/resultados/pacotes',
    exact: true,
    name: 'ScooterInsurance',
    component: ScooterInsurance
  },
  {
    authRequired: false,
    path: '/seguro-trotinete/resultados/comparar/:journeyId?',
    exact: true,
    name: 'CompareScooter',
    component: CompareScooter
  },
  {
    authRequired: false,
    path: '/seguro-trotinete/resultados/summary/:orderId',
    exact: true,
    name: 'SCOOTERSummary',
    component: SCOOTERSummary
  },
  {
    authRequired: false,
    path: '/seguro-trotinete/compra/obrigado/:orderId',
    exact: true,
    name: 'ThankYouScooter',
    component: ThankYouScooter
  },
  {
    path: '/seguro-casa/simulacao/:journeyId?',
    exact: true,
    name: 'HomeJourney',
    component: HomeJourney,
    authRequired: true
  },
  {
    path: '/seguro-casa/resultados/pacotes/:journeyId?',
    exact: true,
    name: 'HomeResults',
    component: HomePackage,
    authRequired: true
  },
  {
    path: '/seguro-casa/resultados/comparar/:journeyId?',
    exact: true,
    name: 'CompareHome',
    component: CompareHome,
    authRequired: true
  },
  {
    path: '/seguro-casa/resultados/summary/:orderId?',
    exact: true,
    name: 'HomeSummary',
    component: HomeSummary,
    authRequired: true
  },
  {
    path: '/seguro-casa/compra/obrigado/:orderId',
    exact: true,
    name: 'ThankYouHome',
    component: ThankYouHome,
    authRequired: true
  },
  {
    path: '/seguro-vida-credito/simulacao/:journeyId?',
    exact: true,
    name: 'MortgageJourney',
    component: MortgageJourney,
    authRequired: false
  },
  {
    path: '/seguro-vida-credito/resultados/pacotes/:journeyId?',
    exact: true,
    name: 'MortgageResults',
    component: MortgageResults,
    authRequired: false
  },
  {
    path: '/seguro-vida-credito/resultados/summary/:orderId?',
    exact: true,
    name: 'MortgageSummary',
    component: MortgageSummary,
    authRequired: false
  },
  {
    path: '/seguro-vida-credito/compra/obrigado/:orderId?',
    exact: true,
    name: 'ThankYouMortgage',
    component: ThankYouMortgage,
    authRequired: false
  },
  {
    path: '/seguro-vida-credito/calculadora-impacto-spread/questionario/:journeyId?',
    exact: true,
    name: 'MortgageCalculator',
    component: MortgageCalculator,
    authRequired: false
  },
  {
    path: '/seguro-vida-credito/calculadora-impacto-spread/resultados/:journeyId?',
    exact: true,
    name: 'MortgageCalculatorResults',
    component: MortgageCalculatorResults,
    authRequired: false
  },
  {
    path: '/seguro-ppr/compra/obrigado/:orderId?',
    exact: true,
    name: 'ThankYouPPR',
    component: ThankYouPPR,
    authRequired: false
  },
  {
    path: '/hospitalização/simulacao/:journeyId?',
    exact: true,
    name: 'Hospitalization',
    component: Hospitalization,
    authRequired: true
  },
  {
    path: '/hospitalização/resultados/pacotes/:journeyId',
    exact: true,
    name: 'HospitalizationResults',
    component: HospitalizationResults,
    authRequired: true
  },
  {
    path: '/hospitalização/resultados/summary/:orderId',
    exact: true,
    name: 'Summary',
    component: HospitalizationSummary,
    authRequired: true
  },
  {
    path: '/hospitalização/compra/obrigado/:orderId',
    exact: true,
    name: 'ThankYouHospitalization',
    component: ThankYouHospitalization,
    authRequired: true
  },
  {
    path: '/seguro-acidentes-trabalho-independente/simulacao/:journeyId?',
    exact: true,
    name: 'AT',
    component: WorkAccident,
    authRequired: false
  },
  {
    path: '/seguro-acidentes-trabalho-independente/resultados/pacotes/:workJourneyId?',
    exact: true,
    name: 'WorkResults',
    component: WorkResults,
    authRequired: false
  },
  {
    path: '/seguro-acidentes-trabalho-independente/resultados/summary/:orderId?',
    exact: true,
    name: 'WorkSummary',
    component: WorkSummary,
    authRequired: false
  },
  ,
  {
    path: '/seguro-acidentes-trabalho-independente/compra/obrigado/:orderId?',
    exact: true,
    name: 'ThankYouWork',
    component: ThankYouWork,
    authRequired: false
  },
  {
    path: '/seguro-viagem/desconto10',
    exact: true,
    name: 'LandingPageEdenred',
    component: LandingPageEdenred,
    authRequired: false
  },
  {
    path: '/seguro-doencas-graves/voucher10',
    exact: true,
    name: 'LandingPageCritical',
    component: LandingPageCritical,
    authRequired: false
  },
  {
    path: '/seguro-animais-domesticos/simulacao/:journeyId?',
    exact: true,
    name: 'PetInsuranceJourney',
    component: PetInsuranceJourney,
    authRequired: false
  },
  {
    path: '/seguro-animais-domesticos/resultados/pacotes/:journeyId?',
    exact: true,
    name: 'PetInsuranceResult',
    component: PetInsuranceResult,
    authRequired: false
  },
  {
    path: '/seguro-animais-domesticos/resultados/comparar/:journeyId?',
    exact: true,
    name: 'PetVerDetalhes',
    component: PetVerDetalhes,
    authRequired: false
  },
  {
    path: '/seguro-animais-domesticos/resultados/summary/:orderId?',
    exact: true,
    name: 'PetSummary',
    component: PetSummary,
    authRequired: false
  },
  {
    path: '/seguro-animais-domesticos/compra/obrigado/:orderId?',
    exact: true,
    name: 'ThankYouPet',
    component: ThankYouPet,
    authRequired: false
  },
  {
    path: '/seguro-alojamento-local/simulacao/:journeyId?',
    exact: true,
    name: 'LocalLodgingJourney',
    component: LocalLodgingJourney,
    authRequired: false
  },
  {
    path: '/seguro-alojamento-local/resultados/pacotes/:journeyId?',
    exact: true,
    name: 'LocalLodgingResult',
    component: LocalLodgingResult,
    authRequired: false
  },
  {
    path: '/seguro-alojamento-local/resultados/summary/:orderId?',
    exact: true,
    name: 'LocalLodgingSummary',
    component: LocalLodgingSummary,
    authRequired: false
  },
  {
    path: '/seguro-alojamento-local/compra/obrigado/:orderId?',
    exact: true,
    name: 'ThankYouLocalLodging',
    component: ThankYouLocalLodging,
    authRequired: false
  },
  {
    path: '/seguro-ppr/simulacao/:journeyId?',
    exact: true,
    name: 'PPRJourneyNew',
    component: PPRJourneyNew,
    authRequired: false
  },
  {
    path: '/seguro-ppr/resultados/informação-adicional/:orderId?',
    exact: true,
    name: 'PPRConfirmation',
    component: PPRConfirmation,
    authRequired: false
  },
  {
    path: '/iframe-redirect-success',
    exact: true,
    name: 'IframeRedirect',
    component: IframeRedirect,
    authRequired: false
  },
  // { path: '/consultor', exact: true, name: 'Advisor', component: Advisor, authRequired: false },
  {
    path: '/consultor-resultados',
    exact: true,
    name: 'AdvisorSuggestion',
    component: AdvisorSuggestion,
    authRequired: false
  },
  {
    path: '/consultor-questionario/:formId?',
    exact: true,
    name: 'AdvisorForms',
    component: AdvisorForms,
    authRequired: false
  },
  {
    path: '/seguro-garantia/simulacao',
    exact: true,
    name: 'KKJourney',
    component: KKJourney,
    authRequired: false
  },
  {
    path: '/seguro-garantia/resultados/pacotes/:journeyId?',
    exact: true,
    name: 'KKResult',
    component: KKResult,
    authRequired: false
  },
  {
    path: "/seguro-garantia/resultados/summary/:orderId?",
    exact: true,
    name: "KKSummary",
    component: KKSummary,
    authRequired: false,
  },
  {
    path: "/seguro-garantia/compra/obrigado/:orderId?",
    exact: true,
    name: "ThankYouKK",
    component: ThankYouKK,
    authRequired: false,
  },
  {
    path: "/seguro-garantia/resultados/payment/:orderId?",
    exact: true,
    name: "PaymentCredit",
    component: PaymentCreditKK,
    authRequired: false,
  },

  // {
  //   path: "/seguro-garantia/resultados/payment/:orderId?",
  //   exact: true,
  //   name: "PaymentCredit",
  //   component: PaymentCredit,
  //   authRequired: false,
  // },
]

export default routes
