// @flow
import Cookies from 'js-cookie'
import * as actionTypes from './actions'
import {
  FILTER_BY,
  SET_ALL_PACKAGES,
  SET_CAR_NON_RECOMMENDED_PACKAGES,
  SET_CAR_RECOMMENDED_PACKAGES,
  SET_COMPARE_PACKAGES,
  SET_FILTER_PACKAGES,
  SET_FILTER_STATUS,
  SET_NON_RECOMMENDED_PACKAGES,
  SET_RECOMMENDED_PACKAGES,
  SORT_BY
} from './constant'
const domain = process.env.NODE_ENV === 'development' ? 'localhost' : '.mudey.pt'

const token = Cookies.get('MUDEY_token', { domain: domain }) || null
const uuid = Cookies.get('MUDEY_uuid', { domain: domain }) || null
const email = Cookies.get('MUDEY_email', { domain: domain }) || null
const name = Cookies.get('MUDEY_name', { domain: domain }) || null

const user = token
  ? {
      email,
      name,
      uuid
    }
  : null

const initialState = {
  isLoading: false,
  journeyId: null,
  comparePackages: [],
  slug: '',
  user: user,
  token: token,
  pathToRedirect: null,
  hasUserLoggedOut: false,
  isAuthenticated: token ? true : false,
  intendedSum: null,
  status: 'out',
  stripe_payment: null,
  questionnarie_id: null,
  questionnarie_active_panel_index: 0,
  questionnarie_additional_data: null,
  policyCost: null,
  health_insurance_data: [],
  health_packages: null,
  car_filters: [],
  life_insurance_data: [],
  isMobile: false,
  personal_accidents_journey_id: null,
  personal_accidents_packages: [],
  housekeeper_packages: [],
  car_packages: null,
  car_recommended_packages: null,
  car_nonrecommended_packages: null,
  criticalIllness_packages: null,
  hospitalization_packages: null,
  birthdatesArray: null,
  car_data: null,
  carPackagesCalled: false,
  pet_packages: null,
  pet_selected_package: null,
  pet_pay_period: null,
  pet_pay_amount: null,
  local_lodging_packages: null,

  recommendedPackages: null,
  nonRecommendedPackages: null,
  allPackages: null,
  filterPackages: null,
  filterStatus: null,
  sortBy: null,
  filterBy: null,
  comparePackages: [],
  set_user_id_back_btn: null,
  set_card_layout: true,
  set_life_journey: null,
  is_proid_in_warranty: false
}

const reducer = (state: Object = initialState, action: Object) => {
  const { payload, type } = action
  switch (type) {
    case actionTypes.LOGIN:
      return {
        ...state,
        token: payload.token,
        isAuthenticated: true,
        user: payload.user,
        status: 'in'
      }
    case actionTypes.LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        user: null,
        status: 'out'
      }
    case actionTypes.HAS_USER_LOGGED_OUT:
      return {
        ...state,
        hasUserLoggedOut: payload
      }
    case actionTypes.UPDATE_COMPARE_PACKAGES:
      return {
        ...state,
        journeyId: payload.journeyId,
        comparePackages: payload.packages,
        slug: payload.slug
      }
    case actionTypes.SET_JOURNEY_ID:
      return {
        ...state,
        journeyId: payload
      }
    case actionTypes.SET_JOURNEY_INTENDED_SUM:
      return {
        ...state,
        intendedSum: payload.intendedSum
      }
    case actionTypes.STRIPE_PAYMENT_SUCCESS:
      return {
        ...state,
        stripe_payment: 'success'
      }
    case actionTypes.STRIPE_PAYMENT_PROGRESS:
      return {
        ...state,
        stripe_payment: 'progress'
      }
    case actionTypes.STRIPE_PAYMENT_FAIL:
      return {
        ...state,
        stripe_payment: 'fail'
      }
    case actionTypes.STRIPE_PAYMENT_CLEAR:
      return {
        ...state,
        stripe_payment: null
      }
    case actionTypes.IN:
      return {
        ...state,
        status: 'in'
      }
    case actionTypes.SET_QUESTIONNAIRE_ID:
      return {
        ...state,
        questionnarie_id: payload
      }
    case actionTypes.SET_QUESTIONNAIRE_ACTIVE_PANEL_INDEX:
      return {
        ...state,
        questionnarie_active_panel_index: payload
      }
    case actionTypes.SET_HEALTH_QUESTIONNARIE_ADDITIONAL_DATA:
      return {
        ...state,
        questionnarie_additional_data: payload
      }
    case actionTypes.SET_POLICY_COST:
      return {
        ...state,
        policyCost: payload
      }

    case actionTypes.SET_HEALTH_INSURANCE_DATA:
      return {
        ...state,
        health_insurance_data: payload
      }

    case actionTypes.SET_HEALTH_INSURANCE_FILTERS:
      return {
        ...state,
        health_insurance_data: {
          ...state.health_insurance_data,
          additionalCover: payload
        }
      }

    case actionTypes.SET_CAR_INSURANCE_FILTERS:
      return {
        ...state,
        car_filters: payload
      }

    case actionTypes.SET_IS_MOBILE:
      return {
        ...state,
        isMobile: payload
      }
    case actionTypes.SET_PATH_TO_REDIRECT:
      return {
        ...state,
        pathToRedirect: payload
      }

    case actionTypes.SET_PERSONAL_ACCIDENTS_PACKAGES:
      return {
        ...state,
        personal_accidents_packages: payload
      }

    case actionTypes.SET_HOUSEKEEPER_PACKAGES:
      return {
        ...state,
        housekeeper_packages: payload
      }

    case actionTypes.SET_PERSONAL_ACCIDENTS_JOURNEY_ID:
      return {
        ...state,
        personal_accidents_journey_id: payload
      }
    case actionTypes.SET_CRITICALILLNESS_PACKAGES:
      return {
        ...state,
        criticalIllness_packages: payload
      }
    case actionTypes.SET_CRITICALILLNESS_BIRTHDATES:
      return {
        ...state,
        birthdatesArray: payload
      }
    case actionTypes.SET_HOSPITALIZATION_PACKAGES:
      return {
        ...state,
        hospitalization_packages: payload
      }
    case actionTypes.SET_HOSPITALIZATION_BIRTHDATES:
      return {
        ...state,
        birthdatesArray: payload
      }
    case actionTypes.SET_CAR_PACKAGES:
      return {
        ...state,
        car_packages: payload
      }

    case actionTypes.SET_CAR_DATA:
      return {
        ...state,
        car_data: payload
      }
    case actionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload
      }
    case actionTypes.SET_CAR_PACKAGES_CALLED:
      return {
        ...state,
        carPackagesCalled: payload
      }
    case actionTypes.SET_HEALTH_PACKAGES:
      return {
        ...state,
        health_packages: payload
      }

    case actionTypes.SET_PET_PACKAGES:
      return {
        ...state,
        pet_packages: payload
      }
    case actionTypes.SET_PET_SELECTED_PACKAGE:
      return {
        ...state,
        pet_selected_package: payload
      }
    case actionTypes.SET_PET_SELECTED_PACKAGE_ID:
      return {
        ...state,
        pet_selected_package_id: payload
      }
    case actionTypes.SET_PET_PAYMENT_PERIOD:
      return {
        ...state,
        pet_pay_period: payload
      }
    case actionTypes.SET_PET_PAYMENT_AMOUNT:
      return {
        ...state,
        pet_pay_amount: payload
      }
    case actionTypes.SET_LOCAL_LODGING_PACKAGES:
      return {
        ...state,
        local_lodging_packages: payload
      }
    case actionTypes.IS_PROID_IN_WARRANTY:
      return {
        ...state,
        is_proid_in_warranty: payload
      }
    case SET_RECOMMENDED_PACKAGES:
      return {
        ...state,
        recommendedPackages: action.payload
      }
    case SET_NON_RECOMMENDED_PACKAGES:
      return { ...state, nonRecommendedPackages: action.payload }
    case SET_ALL_PACKAGES:
      return { ...state, allPackages: action.payload }
    case SET_FILTER_PACKAGES:
      return { ...state, filterPackages: action.payload }
    case SET_FILTER_STATUS:
      return { ...state, filterStatus: action.payload }
    case SORT_BY:
      return { ...state, sortBy: action.payload }
    case FILTER_BY:
      return { ...state, filterBy: action.payload }
    case SET_COMPARE_PACKAGES:
      return { ...state, comparePackages: action.payload }
    case SET_CAR_RECOMMENDED_PACKAGES:
      return { ...state, car_recommended_packages: action.payload }
    case SET_CAR_NON_RECOMMENDED_PACKAGES:
      return { ...state, car_nonrecommended_packages: action.payload }
    case actionTypes.SET_USER_ID_BACK_BTN:
      return { ...state, set_user_id_back_btn: action.payload }
    case actionTypes.SET_CARD_LAYOUT:
      return { ...state, set_card_layout: action.payload }
    case actionTypes.SET_LIFE_JOURNEY:
      return { ...state, set_life_journey: action.payload }
    default:
      return state
  }
}

export default reducer
