import React, { useState, useEffect } from 'react'

import allianz from '~images/icons/insurers_logos/allianz.png'
import april from '~images/icons/insurers_logos/april.jpg'
import asisa from '~images/icons/insurers_logos/asisa.jpg'
import europ from '~images/icons/insurers_logos/europ.jpg'
//import inter from '~images/icons/insurers_logos/inter.jpg'
import lusitania from '~images/icons/insurers_logos/lusitania.jpg'
import metlife from '~images/icons/insurers_logos/metlife.png'
import liberty from '~images/icons/insurers_logos/liberty.png'
import mapfre from '~images/icons/insurers_logos/mapfre.png'
import prevoir from '~images/icons/insurers_logos/prevoir.png'
import saudeprime from '~images/icons/insurers_logos/saudeprime.png'
import tranquilidade from '~images/icons/insurers_logos/tranquilidade.png'

import './index.less'

const insurers = [allianz, april, europ, lusitania, metlife, asisa, liberty, mapfre, prevoir, saudeprime, tranquilidade]

export const ProgressInsurers = ({customStyle}: any) => {
  const animationSpeed = 1500
  const stepSpeed = 600
  const [step, setStep] = useState(0)
  const [anim, setAnim] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setAnim(!anim)
      setTimeout(() => {
        if (step < insurers.length - 1) {
          setStep(step + 1)
        }

        if (step >= insurers.length - 1) {
          setStep(0)
        }
      }, stepSpeed)
    }, animationSpeed)
  }, [anim])

  return (
    <div
      className="insurers_container"
      style={customStyle?customStyle:{
        textIndent: `${anim ? '23px' : 0}`,
      }}>
      <div
        className="insurer_item"
        style={{
          backgroundImage: `url('${insurers[step]}')`,
          transform: `${anim ? 'translateX(0)' : 'translateX(600px)'}`,
          opacity: `${anim ? 1 : 0}`
        }}
      />
    </div>
  )
}
