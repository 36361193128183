// @flow
import countries from './countries.json'

const getCountries = () => countries

const getCountryByCode = (code: string) => countries.find((country: Object) => country.countryShortCode === code)

const mapCountryCodeToName = (code: string) => {
  const foundCountry = getCountryByCode(code)

  return foundCountry ? foundCountry.countryName : code
}

export { getCountryByCode, getCountries, mapCountryCodeToName }

//  on line 16781
// {
//   "countryName": "Ucrânia",
//   "countryShortCode": "UA",
//   "regions": [{
//           "name": "Cherkasy",
//           "shortCode": "71"
//       },
//       {
//           "name": "Chernihiv",
//           "shortCode": "74"
//       },
//       {
//           "name": "Chernivtsi",
//           "shortCode": "77"
//       },
//       {
//           "name": "Dnipropetrovsk",
//           "shortCode": "12"
//       },
//       {
//           "name": "Donetsk",
//           "shortCode": "14"
//       },
//       {
//           "name": "Ivano-Frankivsk",
//           "shortCode": "26"
//       },
//       {
//           "name": "Kharkiv",
//           "shortCode": "63"
//       },
//       {
//           "name": "Kherson",
//           "shortCode": "65"
//       },
//       {
//           "name": "Khmelnytskyi",
//           "shortCode": "68"
//       },
//       {
//           "name": "Kiev",
//           "shortCode": "32"
//       },
//       {
//           "name": "Kirovohrad",
//           "shortCode": "35"
//       },
//       {
//           "name": "Luhansk",
//           "shortCode": "09"
//       },
//       {
//           "name": "Lviv",
//           "shortCode": "46"
//       },
//       {
//           "name": "Mykolaiv",
//           "shortCode": "48"
//       },
//       {
//           "name": "Odessa",
//           "shortCode": "51"
//       },
//       {
//           "name": "Poltava",
//           "shortCode": "53"
//       },
//       {
//           "name": "Rivne",
//           "shortCode": "56"
//       },
//       {
//           "name": "Sumy",
//           "shortCode": "59"
//       },
//       {
//           "name": "Ternopil",
//           "shortCode": "61"
//       },
//       {
//           "name": "Vinnytsia",
//           "shortCode": "05"
//       },
//       {
//           "name": "Volyn",
//           "shortCode": "07"
//       },
//       {
//           "name": "Zakarpattia",
//           "shortCode": "21"
//       },
//       {
//           "name": "Zaporizhia",
//           "shortCode": "23"
//       },
//       {
//           "name": "Zhytomyr",
//           "shortCode": "18"
//       },
//       {
//           "name": "Avtonomna Respublika Krym",
//           "shortCode": "43"
//       },
//       {
//           "name": "Kyïv",
//           "shortCode": "30"
//       },
//       {
//           "name": "Sevastopol",
//           "shortCode": "40"
//       }
//   ]
// },
//  on line 12483
// {
//     "countryName": "Rússia",
//     "countryShortCode": "RU",
//     "regions": [{
//             "name": "Republic of Adygea",
//             "shortCode": "AD"
//         },
//         {
//             "name": "Republic of Altai (Gorno-Altaysk)",
//             "shortCode": "AL"
//         },
//         {
//             "name": "Altai Krai",
//             "shortCode": "ALT"
//         },
//         {
//             "name": "Amur Oblast",
//             "shortCode": "AMU"
//         },
//         {
//             "name": "Arkhangelsk Oblast",
//             "shortCode": "ARK"
//         },
//         {
//             "name": "Astrakhan Oblast",
//             "shortCode": "AST"
//         },
//         {
//             "name": "Republic of Bashkortostan",
//             "shortCode": "BA"
//         },
//         {
//             "name": "Belgorod Oblast",
//             "shortCode": "BEL"
//         },
//         {
//             "name": "Bryansk Oblast",
//             "shortCode": "BRY"
//         },
//         {
//             "name": "Republic of Buryatia",
//             "shortCode": "BU"
//         },
//         {
//             "name": "Chechen Republic",
//             "shortCode": "CE"
//         },
//         {
//             "name": "Chelyabinsk Oblast",
//             "shortCode": "CHE"
//         },
//         {
//             "name": "Chukotka Autonomous Okrug",
//             "shortCode": "CHU"
//         },
//         {
//             "name": "Chuvash Republic",
//             "shortCode": "CU"
//         },
//         {
//             "name": "Republic of Dagestan",
//             "shortCode": "DA"
//         },
//         {
//             "name": "Republic of Ingushetia",
//             "shortCode": "IN"
//         },
//         {
//             "name": "Irkutsk Oblast",
//             "shortCode": "IRK"
//         },
//         {
//             "name": "Ivanovo Oblast",
//             "shortCode": "IVA"
//         },
//         {
//             "name": "Jewish Autonomous Oblast",
//             "shortCode": "JEW"
//         },
//         {
//             "name": "Kabardino-Balkar Republic",
//             "shortCode": "KB"
//         },
//         {
//             "name": "Kaliningrad Oblast",
//             "shortCode": "KLN"
//         },
//         {
//             "name": "Republic of Kalmykia",
//             "shortCode": "KL"
//         },
//         {
//             "name": "Kaluga Oblast",
//             "shortCode": "KLU"
//         },
//         {
//             "name": "Kamchatka Krai",
//             "shortCode": "KAM"
//         },
//         {
//             "name": "Karachay-Cherkess Republic",
//             "shortCode": "KC"
//         },
//         {
//             "name": "Republic of Karelia",
//             "shortCode": "KR"
//         },
//         {
//             "name": "Khabarovsk Krai",
//             "shortCode": "KHA"
//         },
//         {
//             "name": "Republic of Khakassia",
//             "shortCode": "KK"
//         },
//         {
//             "name": "Khanty-Mansi Autonomous Okrug - Yugra",
//             "shortCode": "KHM"
//         },
//         {
//             "name": "Kemerovo Oblast",
//             "shortCode": "KEM"
//         },
//         {
//             "name": "Kirov Oblast",
//             "shortCode": "KIR"
//         },
//         {
//             "name": "Komi Republic",
//             "shortCode": "KO"
//         },
//         {
//             "name": "Kostroma Oblast",
//             "shortCode": "KOS"
//         },
//         {
//             "name": "Krasnodar Krai",
//             "shortCode": "KDA"
//         },
//         {
//             "name": "Krasnoyarsk Krai",
//             "shortCode": "KYA"
//         },
//         {
//             "name": "Kurgan Oblast",
//             "shortCode": "KGN"
//         },
//         {
//             "name": "Kursk Oblast",
//             "shortCode": "KRS"
//         },
//         {
//             "name": "Leningrad Oblast",
//             "shortCode": "LEN"
//         },
//         {
//             "name": "Lipetsk Oblast",
//             "shortCode": "LIP"
//         },
//         {
//             "name": "Magadan Oblast",
//             "shortCode": "MAG"
//         },
//         {
//             "name": "Mari El Republic",
//             "shortCode": "ME"
//         },
//         {
//             "name": "Republic of Mordovia",
//             "shortCode": "MO"
//         },
//         {
//             "name": "Moscow Oblast",
//             "shortCode": "MOS"
//         },
//         {
//             "name": "Moscow",
//             "shortCode": "MOW"
//         },
//         {
//             "name": "Murmansk Oblast",
//             "shortCode": "MU"
//         },
//         {
//             "name": "Nenets Autonomous Okrug",
//             "shortCode": "NEN"
//         },
//         {
//             "name": "Nizhny Novgorod Oblast",
//             "shortCode": "NIZ"
//         },
//         {
//             "name": "Novgorod Oblast",
//             "shortCode": "NGR"
//         },
//         {
//             "name": "Novosibirsk Oblast",
//             "shortCode": "NVS"
//         },
//         {
//             "name": "Omsk Oblast",
//             "shortCode": "OMS"
//         },
//         {
//             "name": "Orenburg Oblast",
//             "shortCode": "ORE"
//         },
//         {
//             "name": "Oryol Oblast",
//             "shortCode": "ORL"
//         },
//         {
//             "name": "Penza Oblast",
//             "shortCode": "PNZ"
//         },
//         {
//             "name": "Perm Krai",
//             "shortCode": "PER"
//         },
//         {
//             "name": "Primorsky Krai",
//             "shortCode": "PRI"
//         },
//         {
//             "name": "Pskov Oblast",
//             "shortCode": "PSK"
//         },
//         {
//             "name": "Rostov Oblast",
//             "shortCode": "ROS"
//         },
//         {
//             "name": "Ryazan Oblast",
//             "shortCode": "RYA"
//         },
//         {
//             "name": "Saint Petersburg",
//             "shortCode": "SPE"
//         },
//         {
//             "name": "Sakha (Yakutia) Republic",
//             "shortCode": "SA"
//         },
//         {
//             "name": "Sakhalin Oblast",
//             "shortCode": "SAK"
//         },
//         {
//             "name": "Samara Oblast",
//             "shortCode": "SAM"
//         },
//         {
//             "name": "Saratov Oblast",
//             "shortCode": "SAR"
//         },
//         {
//             "name": "Republic of North Ossetia-Alania",
//             "shortCode": "NOA"
//         },
//         {
//             "name": "Smolensk Oblast",
//             "shortCode": "SMO"
//         },
//         {
//             "name": "Stavropol Krai",
//             "shortCode": "STA"
//         },
//         {
//             "name": "Sverdlovsk Oblast",
//             "shortCode": "SVE"
//         },
//         {
//             "name": "Tambov Oblast",
//             "shortCode": "TAM"
//         },
//         {
//             "name": "Republic of Tatarstan",
//             "shortCode": "TA"
//         },
//         {
//             "name": "Tomsk Oblast",
//             "shortCode": "TOM"
//         },
//         {
//             "name": "Tuva Republic",
//             "shortCode": "TU"
//         },
//         {
//             "name": "Tula Oblast",
//             "shortCode": "TUL"
//         },
//         {
//             "name": "Tver Oblast",
//             "shortCode": "TVE"
//         },
//         {
//             "name": "Tyumen Oblast",
//             "shortCode": "TYU"
//         },
//         {
//             "name": "Udmurt Republic",
//             "shortCode": "UD"
//         },
//         {
//             "name": "Ulyanovsk Oblast",
//             "shortCode": "ULY"
//         },
//         {
//             "name": "Vladimir Oblast",
//             "shortCode": "VLA"
//         },
//         {
//             "name": "Volgograd Oblast",
//             "shortCode": "VGG"
//         },
//         {
//             "name": "Vologda Oblast",
//             "shortCode": "VLG"
//         },
//         {
//             "name": "Voronezh Oblast",
//             "shortCode": "VOR"
//         },
//         {
//             "name": "Yamalo-Nenets Autonomous Okrug",
//             "shortCode": "YAN"
//         },
//         {
//             "name": "Yaroslavl Oblast",
//             "shortCode": "YAR"
//         },
//         {
//             "name": "Zabaykalsky Krai",
//             "shortCode": "ZAB"
//         }
//     ]
// },