// @flow
import get from 'lodash/get'
import toString from 'lodash/toString'
import isEmpty from 'lodash/isEmpty'
import { mapCountryCodeToName } from './countries'
import moment from 'moment'

const travelJourney: any = {
  options: {
    activitiesEquipments: [{ code: true, description: 'Sim' }, { code: false, description: 'Não' }],
    connectionFlights: [{ code: true, description: 'Sim' }, { code: false, description: 'Não' }],
    riskActivities: [
      { code: 'EXTREME', description: 'Desportos Radicais' },
      { code: 'ADVENTURE', description: 'Desportos de Aventura' },
      { code: 'WINTER', description: 'Desportos de Inverno' }
    ],
    travelBaggage: [
      { code: 'AIRPLANECABIN', description: 'Na cabine do Avião' },
      { code: 'AIRPLANEFLOOR', description: 'No porão do avião' }
    ],
    travelType: [
      { code: 'SINGLE', description: `Temporário` },
      { code: 'ANNUAL', description: `Anual / Multi-viagem` }
    ],
    travelersQuantity: [
      { code: 1, description: 'Apenas Eu' },
      { code: 2, description: '1' },
      { code: 3, description: '2' },
      { code: 4, description: '3' }
    ],
    travelValuation: [
      { code: 'LOW', description: 'Menos de 500€ por pessoa' },
      { code: 'NORMAL', description: 'Entre 500€ e 1500€ por pessoa' },
      { code: 'HIGH', description: 'Mais de 1500€ por pessoa' }
    ],
    travelReason: [
      { code: 'WORK', description: 'Trabalho' },
      { code: 'RECREATION', description: 'Lazer' },
      { code: 'BOTH', description: 'Ambas as anteriores' }
    ],
    travelTransports: [
      { code: 'AIR', description: 'Aéreo' },
      { code: 'WATER', description: 'Marítimo' },
      { code: 'LAND', description: 'Terreste' }
    ]
  },
  getOptionsByKey: (key: string) => {
    return travelJourney.options[key]
  },

  getKeyOptionDescriptionByCode: (key: string, code: string) => {
    const options = travelJourney.getOptionsByKey(key)

    const option = options && options.length > 0 ? options.find((item: Object) => item.code === code) : null
    return option ? option.description : ''
  },
  mapJourneyAPIDataToPanel: (data: Object) => {
    const {
      activitiesEquipments,
      connectionFlights,
      locations,
      otherTravelersAge = [],
      riskActivities = [],
      travelerAge,
      travelBaggage = [],
      travelersQuantity,
      travelTransports = [],
      travelValuation,
      travelType,
      travelReason,
      tripStartDate,
      tripEndDate
    }: Object = data
    return {
      activitiesEquipments: {
        code: activitiesEquipments,
        description: travelJourney.getKeyOptionDescriptionByCode('activitiesEquipments', activitiesEquipments)
      },
      connectionFlights: {
        code: connectionFlights,
        description: travelJourney.getKeyOptionDescriptionByCode('connectionFlights', connectionFlights)
      },
      dates: tripStartDate && tripEndDate ? [moment(tripStartDate), moment(tripEndDate)] : [],
      locations,
      riskActivities: riskActivities.map((activity: Object) => ({
        code: activity,
        description: travelJourney.getKeyOptionDescriptionByCode('riskActivities', activity) || 'Nenhuma'
      })),
      travelerAge,
      traveler2Age: otherTravelersAge.length > 0 ? otherTravelersAge[0] : null,
      traveler3Age: otherTravelersAge.length > 1 ? otherTravelersAge[1] : null,
      traveler4Age: otherTravelersAge.length > 2 ? otherTravelersAge[2] : null,
      travelType: {
        code: travelType,
        description: travelJourney.getKeyOptionDescriptionByCode('travelType', travelType)
      },
      travelReason: {
        code: travelReason,
        description: travelJourney.getKeyOptionDescriptionByCode('travelReason', travelReason)
      },
      travelersQuantity: {
        code: travelersQuantity,
        description: travelJourney.getKeyOptionDescriptionByCode('travelersQuantity', travelersQuantity)
      },
      travelValuation: {
        code: travelValuation,
        description: travelJourney.getKeyOptionDescriptionByCode('travelValuation', travelValuation)
      },
      travelTransports: travelTransports.map((transport: Object) => ({
        code: transport,
        description: travelJourney.getKeyOptionDescriptionByCode('travelTransports', transport)
      })),
      travelBaggage: travelBaggage.map((baggage: Object) => ({
        code: baggage,
        description: travelJourney.getKeyOptionDescriptionByCode('travelBaggage', baggage)
      }))
    }
  },
  mapJourneyPanelToAPIData: (data: Object) => {
    const {
      activitiesEquipments,
      connectionFlights,
      dates,
      locations,
      riskActivities,
      travelerAge,
      traveler2Age,
      traveler3Age,
      traveler4Age,
      travelBaggage,
      travelersQuantity,
      travelValuation,
      travelTransports,
      travelType,
      travelReason
    }: Object = data

    return {
      activitiesEquipments: activitiesEquipments.code,
      connectionFlights: connectionFlights.code,
      locations,
      otherTravelersAge: [traveler2Age, traveler3Age, traveler4Age].filter((item: Object) => item),
      riskActivities: riskActivities.map((activity: Object) => activity.code),
      travelerAge,
      travelType: travelType.code,
      travelReason: travelReason.code,
      travelersQuantity: travelersQuantity.code,
      travelValuation: travelValuation.code,
      travelTransports: travelTransports.map((transport: Object) => transport.code),
      travelBaggage: travelBaggage.map((baggage: Object) => baggage.code),
      tripStartDate: !isEmpty(dates) ? moment(dates[0]).format('YYYY-MM-DD') : null,
      tripEndDate: !isEmpty(dates) ? moment(dates[1]).format('YYYY-MM-DD') : null
    }
  },
  mapJourneyDetailsFromAPIData: (data: Object) => {
    const {
      locations = [],
      travelerAge,
      travelersQuantity,
      travelType,
      travelValuation,
      travelReason,
      tripStartDate,
      tripEndDate,
      travelTransports,
      riskActivities,
      price
    } = data || {}

    const trabelReasonDescription = travelJourney.getKeyOptionDescriptionByCode('travelReason', travelReason)
    const newTravelReason =
      trabelReasonDescription === 'Ambas as anteriores' ? 'Trabalho / Lazer' : trabelReasonDescription

    return [
      {
        title: 'Tipo de Seguro',
        value: travelType === 'ANNUAL' ? 'Anual' : 'Temporário',
        resume: true
      },
      {
        title: 'Destino',
        value: locations.map((location: string) => mapCountryCodeToName(location)).join(', '),
        resume: true
      },
      {
        title: travelType === 'SINGLE' ? null : 'Motivo',
        value: travelType === 'SINGLE' ? null : newTravelReason,
        resume: true
      },
      {
        title: travelType === 'ANNUAL' ? null : 'Datas',
        value: travelType === 'ANNUAL' ? null : `${tripStartDate} - ${tripEndDate}`,
        resume: true
      },
      {
        title: 'Preço por pessoa',
        value: `€${parseFloat(price).toFixed(2)}`,
        resume: true
      },
      {
        title: 'Idade do segurado',
        value: travelerAge
      },
      {
        title: 'Viajantes adicionais',
        value: travelersQuantity ? travelersQuantity : 'Nenhum'
      },
      {
        title: 'Custo da Viagem',
        value: travelValuation
          ? travelJourney.options.travelValuation.find((valuation: Object) => valuation.code === travelValuation)
              .description
          : ''
      },
      {
        title: 'Tipo de Transporte',
        value: travelTransports
          ? travelTransports
              .map(
                (transport: Object) =>
                  travelJourney.options.travelTransports.find(
                    (travelTransport: Object) => travelTransport.code === transport
                  ).description
              )
              .join(', ')
          : 'Nenhum'
      },
      {
        title: 'Atividades',
        value: riskActivities
          ? riskActivities
              .map((activity: Object) => {
                const foundActivity =
                  travelJourney.options.riskActivities.find((riskActivity: Object) => riskActivity.code === activity) ||
                  {}

                return foundActivity ? foundActivity.description : ''
              })
              .join(', ')
          : 'Nenhuma'
      }
    ]
  }
}

const childJourney: any = {
  options: {
    extras: [
      { code: 'MEDICAL_EXPENSES', description: 'Reembolso de Despesas Médicas' },
      { code: 'HOSPITALIZATION', description: 'Subsidio por Hospitalização' },
      { code: 'TEMPORARY_DISABILITY', description: 'Subsidio por Incapacidade Temporária' }
    ],

    about: [
      { code: 'SELF_EMPLOYEED', description: 'Trabalho por conta própria' },
      { code: 'RISK_SPORTS', description: 'Pratico desportos aventura e/ou radicais' },
      { code: 'HAS_MOTOCYCLE', description: 'Tenho moto com 250cc ou superior' },
      { code: 'WORK_TRAVEL', description: 'Viajo bastante a trabalho' },
      { code: 'DANGEROUS_JOB', description: 'O meu trabalho é "perigoso"' }
    ],
    additionalCover: [
      { code: 'INV_TOTAL_PERM', description: 'Invalidez Total e Permanente' },
      { code: 'INV_ABSOLUTE_DEFINITIVE', description: 'Invalidez Absoluta e Definitiva' }
      //{ code: 'DEATH_BY_ACCIDENT', description: 'Morte por Acidente de Circulação' }
    ],
    portugueseResident: [
      { code: 'MORETHANSIX', description: `Mais de 6 meses por ano` },
      { code: 'LESSTHANSIX', description: `Menos de 6 meses por ano` }
    ],
    nif: [{ code: true, description: 'Sim' }, { code: false, description: 'Não' }],
    underageDependents: [{ code: true, description: 'Sim' }, { code: false, description: 'Não' }],
    family: [
      { code: 'JUSTME', description: 'Sou apenas eu' },
      { code: 'TWO', description: 'Somos dois' },
      { code: 'MORETHANTWO', description: 'Somos mais que dois' }
    ],
    insuranceMotive: [
      { code: 'RECOMMENDED', description: 'Porque me foi recomendado' },
      { code: 'COMPARE', description: 'Quero comparar com o seguro que tenho atualmente' },
      { code: 'PROTECTION', description: 'Para estar protegido(a) caso algo aconteça' },
      { code: 'RENEWSOON', description: 'O meu seguro vai renovar-se em breve' }
    ],
    underage: [{ code: true, description: 'Sim' }, { code: false, description: 'Não' }]
  },
  getOptionsByKey: (key: string) => {
    return childJourney.options[key]
  },
  getKeyOptionDescriptionByCode: (key: string, code: string) => {
    const options = childJourney.getOptionsByKey(key)
    const option = options && options.length > 0 ? options.find((item: Object) => item.code === code) : null
    return option ? option.description : ''
  },
  mapJourneyAPIDataToPanel: (data: Object) => {
    const {
      intendedSumAssured,
      intendedSum,
      nif,
      underageDependents,
      extras = [],
      additionalCover = [],
      age,
      birthdate,
      profession,
      about = [],
      portugueseResident,
      family,
      insuranceMotive
    }: any = data

    return {
      nif: {
        code: nif,
        description: childJourney.getKeyOptionDescriptionByCode('nif', nif)
      },
      underageDependents: {
        code: underageDependents,
        description: childJourney.getKeyOptionDescriptionByCode('underageDependents', underageDependents)
      },
      extras: extras.map((activity: Object) => ({
        code: activity,
        description: childJourney.getKeyOptionDescriptionByCode('extras', activity) || 'Nenhuma'
      })),
      additionalCover: additionalCover.map((value: Object) => ({
        code: value,
        description: childJourney.getKeyOptionDescriptionByCode('additionalCover', value) || 'Nenhuma'
      })),
      age,
      birthdate,
      profession,
      portugueseResident: {
        code: portugueseResident,
        description: childJourney.getKeyOptionDescriptionByCode('portugueseResident', portugueseResident)
      },
      intendedSumAssured,
      intendedSum,
      about: about
        ? about.map((value: string) => ({
            code: value,
            description: childJourney.getKeyOptionDescriptionByCode('about', value) || 'Nenhuma'
          }))
        : [],
      family: {
        code: family,
        description: childJourney.getKeyOptionDescriptionByCode('family', family)
      },
      insuranceMotive: {
        code: insuranceMotive,
        description: childJourney.getKeyOptionDescriptionByCode('insuranceMotive', insuranceMotive)
      }
    }
  },
  mapJourneyPanelToAPIData: (data: Object) => {
    const {
      additionalCover,
      nif,
      underageDependents,
      extras,
      age,
      birthdate,
      profession,
      intendedSumAssured,
      intendedSum,
      about,
      portugueseResident,
      family,
      insuranceMotive
    } = data

    return {
      nif: nif.code,
      underageDependents: underageDependents.code,
      extras: extras.map((extras: Object) => extras.code),
      age,
      birthdate,
      profession,
      intendedSumAssured,
      intendedSum,
      portugueseResident: portugueseResident.code,
      additionalCover: additionalCover.map((value: Object) => value.code),
      about: about ? about.map((value: Object) => value.code) : [],
      family: family.code,
      insuranceMotive: insuranceMotive.code
    }
  },
  mapJourneyDetailsFromAPIData: (data: Object) => {
    return [
      {
        title: 'Seguro',
        value: 'Seguro Criança',
        resume: true
      },
      {
        title: 'Seguradora',
        value: get(data, 'package.provider.name'),
        resume: true
      },
      {
        title: 'Nome do Produto',
        value: get(data, 'package.productName'),
        resume: true
      }
    ]
  }
}
const bikeJourney: any = {
  options: {
    extras: [
      { code: 'MEDICAL_EXPENSES', description: 'Reembolso de Despesas Médicas' },
      { code: 'HOSPITALIZATION', description: 'Subsidio por Hospitalização' },
      { code: 'TEMPORARY_DISABILITY', description: 'Subsidio por Incapacidade Temporária' }
    ],

    about: [
      { code: 'SELF_EMPLOYEED', description: 'Trabalho por conta própria' },
      { code: 'RISK_SPORTS', description: 'Pratico desportos aventura e/ou radicais' },
      { code: 'HAS_MOTOCYCLE', description: 'Tenho moto com 250cc ou superior' },
      { code: 'WORK_TRAVEL', description: 'Viajo bastante a trabalho' },
      { code: 'DANGEROUS_JOB', description: 'O meu trabalho é "perigoso"' }
    ],
    additionalCover: [
      { code: 'INV_TOTAL_PERM', description: 'Invalidez Total e Permanente' },
      { code: 'INV_ABSOLUTE_DEFINITIVE', description: 'Invalidez Absoluta e Definitiva' }
      //{ code: 'DEATH_BY_ACCIDENT', description: 'Morte por Acidente de Circulação' }
    ],
    portugueseResident: [
      { code: 'MORETHANSIX', description: `Mais de 6 meses por ano` },
      { code: 'LESSTHANSIX', description: `Menos de 6 meses por ano` }
    ],
    nif: [{ code: true, description: 'Sim' }, { code: false, description: 'Não' }],
    underageDependents: [{ code: true, description: 'Sim' }, { code: false, description: 'Não' }],
    family: [
      { code: 'JUSTME', description: 'Sou apenas eu' },
      { code: 'TWO', description: 'Somos dois' },
      { code: 'MORETHANTWO', description: 'Somos mais que dois' }
    ],
    insuranceMotive: [
      { code: 'RECOMMENDED', description: 'Porque me foi recomendado' },
      { code: 'COMPARE', description: 'Quero comparar com o seguro que tenho atualmente' },
      { code: 'PROTECTION', description: 'Para estar protegido(a) caso algo aconteça' },
      { code: 'RENEWSOON', description: 'O meu seguro vai renovar-se em breve' }
    ],
    underage: [{ code: true, description: 'Sim' }, { code: false, description: 'Não' }]
  },
  getOptionsByKey: (key: string) => {
    return bikeJourney.options[key]
  },
  getKeyOptionDescriptionByCode: (key: string, code: string) => {
    const options = bikeJourney.getOptionsByKey(key)
    const option = options && options.length > 0 ? options.find((item: Object) => item.code === code) : null
    return option ? option.description : ''
  },
  mapJourneyAPIDataToPanel: (data: Object) => {
    const {
      intendedSumAssured,
      intendedSum,
      nif,
      underageDependents,
      extras = [],
      additionalCover = [],
      age,
      birthdate,
      profession,
      about = [],
      portugueseResident,
      family,
      insuranceMotive
    }: any = data

    return {
      nif: {
        code: nif,
        description: bikeJourney.getKeyOptionDescriptionByCode('nif', nif)
      },
      underageDependents: {
        code: underageDependents,
        description: bikeJourney.getKeyOptionDescriptionByCode('underageDependents', underageDependents)
      },
      extras: extras.map((activity: Object) => ({
        code: activity,
        description: bikeJourney.getKeyOptionDescriptionByCode('extras', activity) || 'Nenhuma'
      })),
      additionalCover: additionalCover.map((value: Object) => ({
        code: value,
        description: bikeJourney.getKeyOptionDescriptionByCode('additionalCover', value) || 'Nenhuma'
      })),
      age,
      birthdate,
      profession,
      portugueseResident: {
        code: portugueseResident,
        description: bikeJourney.getKeyOptionDescriptionByCode('portugueseResident', portugueseResident)
      },
      intendedSumAssured,
      intendedSum,
      about: about
        ? about.map((value: string) => ({
            code: value,
            description: bikeJourney.getKeyOptionDescriptionByCode('about', value) || 'Nenhuma'
          }))
        : [],
      family: {
        code: family,
        description: bikeJourney.getKeyOptionDescriptionByCode('family', family)
      },
      insuranceMotive: {
        code: insuranceMotive,
        description: bikeJourney.getKeyOptionDescriptionByCode('insuranceMotive', insuranceMotive)
      }
    }
  },
  mapJourneyPanelToAPIData: (data: Object) => {
    const {
      additionalCover,
      nif,
      underageDependents,
      extras,
      age,
      birthdate,
      profession,
      intendedSumAssured,
      intendedSum,
      about,
      portugueseResident,
      family,
      insuranceMotive
    } = data

    return {
      nif: nif.code,
      underageDependents: underageDependents.code,
      extras: extras.map((extras: Object) => extras.code),
      age,
      birthdate,
      profession,
      intendedSumAssured,
      intendedSum,
      portugueseResident: portugueseResident.code,
      additionalCover: additionalCover.map((value: Object) => value.code),
      about: about ? about.map((value: Object) => value.code) : [],
      family: family.code,
      insuranceMotive: insuranceMotive.code
    }
  },
  mapJourneyDetailsFromAPIData: (data: Object) => {
    return [
      {
        title: 'Seguro',
        value: 'Seguro Bicicleta',
        resume: true
      },
      {
        title: 'Seguradora',
        value: get(data, 'package.provider.name'),
        resume: true
      },
      {
        title: 'Nome do Produto',
        value: get(data, 'package.productName'),
        resume: true
      }
    ]
  }
}
export const healthJourney: any = {
  mapJourneyDetailsFromAPIData: (data: Object) => {
    return [
      {
        title: 'Seguro',
        value: 'Seguro Saúde',
        resume: true
      },
      {
        title: 'Seguradora',
        value: get(data, 'package.provider.name'),
        resume: true
      },
      {
        title: 'Nome do Produto',
        value: get(data, 'package.productName'),
        resume: true
      }
    ]
  },
  mapJourneyAPIDataToPanel: (data: Object) => {
    const {
      insuranceMotive,
      additionalCover,
      birthdate,
      insuredPersons,
      personsBirthdate,
      personsRelation,
      contactNumber
    }: any = data

    const additionalCoverMapped = additionalCover.reduce((acc: any, cover: any) => {
      acc[`additionalCover_${cover}`] = true
      return acc
    }, {})

    const insuranceMotiveOptions = [
      { description: 'Porque me foi recomendado', code: 'RECOMMENDED' },
      { description: 'Quero comparar com o seguro que tenho atualmente', code: 'COMPARE' },
      { description: 'Para estar protegido(a)', code: 'PROTECTION' },
      { description: 'O meu seguro vai renovar em breve', code: 'RENEWSOON' }
    ]

    const relationsOptions = [{ code: 'SPOUSE', description: 'Conjuge' }, { code: 'CHILDREN', description: 'Filho(a)' }]

    const formated = {
      contactNumber: contactNumber || null,
      insuranceMotive: insuranceMotive ? insuranceMotiveOptions.find((im: any) => im.code === insuranceMotive) : null,
      additionalCover: additionalCoverMapped,
      birthdate: birthdate || null,
      insuredPersons: insuredPersons || 0,
      birthdatePerson_1: birthdate || null,
      birthdatePerson_2: personsBirthdate ? personsBirthdate[0] : null,
      birthdatePerson_3: personsBirthdate ? personsBirthdate[1] : null,
      birthdatePerson_4: personsBirthdate ? personsBirthdate[2] : null,
      birthdatePerson_5: personsBirthdate ? personsBirthdate[3] : null,
      personsRelations_1: personsRelation ? personsRelation[0] : null,
      personsRelations_2: personsRelation ? personsRelation[1] : null,
      personsRelations_3: personsRelation ? personsRelation[2] : null
    }
    return formated
  },
  getKeyOptionDescriptionByCode: (key: string, code: string) => {
    //STOMATOLOGY

    const options = [
      { description: 'Hospitalização', code: 'HOSPITALIZATION' },
      { description: 'Consultas Médicas', code: 'MEDICAL_APPOINTMENTS' },
      { description: 'Exames Médicos', code: 'MEDICAL_EXAMS' },
      { description: 'Medicina Dentária', code: 'DENTAL' },
      { description: 'Oftalmologia', code: 'OPHTHALMOLOGY' },
      { description: 'Parto', code: 'CHILDBIRTH' },
      { description: 'Fisioterapia', code: 'PHYSIOTHERAPY' },
      { description: 'Doenças Graves', code: 'SERIOUS_DISEASES' }
    ]
    const option = options.find((item: Object) => item.code === code)
    return option ? option.description : ''
  },
  mapJourneyPanelToAPIData: (data: Object) => {
    const {
      contactNumber,
      insuredPersons = 0,
      insuranceMotive,
      additionalCover,
      birthdate,
      birthdatePerson_1,
      birthdatePerson_2,
      birthdatePerson_3,
      birthdatePerson_4,
      birthdatePerson_5,
      personsRelation,
      personsRelations_1 = null,
      personsRelations_2 = null,
      personsRelations_3 = null
    } = data

    const relations = () => {
      let result = [personsRelations_1, personsRelations_2, personsRelations_3]
      if (!insuredPersons) {
        return []
      }
      result = result.slice(0, insuredPersons - 1)
      return result.filter((p: any) => p)
    }

    const persons = () => {
      let result = [birthdatePerson_2, birthdatePerson_3, birthdatePerson_4, birthdatePerson_5]
      if (!insuredPersons) {
        return []
      }
      result = result.slice(0, insuredPersons - 1)
      return result.filter((p: any) => p)
    }

    const formated = {
      insuranceMotive: insuranceMotive ? insuranceMotive.code : null,
      birthdate: birthdatePerson_1 || null,
      insuredPersons: insuredPersons || 0,
      personsBirthdate: persons(),
      personsRelation: relations(),
      contactNumber: contactNumber || null
    }
    return formated
  }
}
export const carJourney: any = {
  mapJourneyDetailsFromAPIData: (data: Object) => {
    return [
      {
        title: 'Seguro',
        value: 'Seguro Carro',
        resume: true
      },
      {
        title: 'Seguradora',
        value: get(data, 'package.provider.name'),
        resume: true
      },
      {
        title: 'Nome do Produto',
        value: get(data, 'package.productName'),
        resume: true
      }
    ]
  }
}
export const dentalJourney: any = {
  mapJourneyDetailsFromAPIData: (data: Object) => {
    return [
      {
        title: 'Seguro',
        value: 'Seguro Dentário',
        resume: true
      },
      {
        title: 'Seguradora',
        value: get(data, 'package.provider.name'),
        resume: true
      },
      {
        title: 'Nome do Produto',
        value: get(data, 'package.productName'),
        resume: true
      }
    ]
  },
  mapJourneyAPIDataToPanel: (data: Object) => {
    const { insuranceMotive, birthdate, insuredPersons, paymentPeriod, personsBirthdate, personsRelation }: any = data

    const insuranceMotiveOptions = [
      { description: 'Porque me foi recomendado', code: 'RECOMMENDED' },
      { description: 'Quero comparar com o seguro que tenho atualmente', code: 'COMPARE' },
      { description: 'Para estar protegido(a)', code: 'PROTECTION' },
      { description: 'O meu seguro vai renovar em breve', code: 'RENEWSOON' }
    ]

    const paymentPeriodOptions = [
      { description: 'Anual', code: 1 },
      { description: 'Mensal', code: 12 },
      { description: 'Trimestral', code: 4 },
      { description: 'Semestral', code: 2 }
    ]

    const formated = {
      insuranceMotive: insuranceMotive ? insuranceMotiveOptions.find((im: any) => im.code === insuranceMotive) : null,
      birthdate: birthdate || null,
      insuredPersons: insuredPersons || 0,
      paymentPeriod: paymentPeriod ? paymentPeriodOptions.find((im: any) => im.code === paymentPeriod) : null,
      birthdatePerson_1: birthdate || null,
      birthdatePerson_2: personsBirthdate ? personsBirthdate[0] : null,
      birthdatePerson_3: personsBirthdate ? personsBirthdate[1] : null,
      birthdatePerson_4: personsBirthdate ? personsBirthdate[2] : null,
      birthdatePerson_5: personsBirthdate ? personsBirthdate[3] : null,
      personsRelations_1: personsRelation ? personsRelation[0] : null,
      personsRelations_2: personsRelation ? personsRelation[1] : null,
      personsRelations_3: personsRelation ? personsRelation[2] : null,
      personsRelations_4: personsRelation ? personsRelation[3] : null
    }
    return formated
  },
  mapJourneyPanelToAPIData: (data: Object) => {
    const {
      insuredPersons = 0,
      insuranceMotive,
      paymentPeriod,
      birthdatePerson_1,
      birthdatePerson_2,
      birthdatePerson_3,
      birthdatePerson_4,
      birthdatePerson_5,
      personsRelation,
      personsRelations_1 = null,
      personsRelations_2 = null,
      personsRelations_3 = null,
      personsRelations_4 = null
    } = data

    const relations = () => {
      let result = [personsRelations_1, personsRelations_2, personsRelations_3, personsRelations_4]
      if (!insuredPersons) {
        return []
      }
      result = result.slice(0, insuredPersons - 1)
      return result.filter((p: any) => p)
    }

    const persons = () => {
      let result = [birthdatePerson_2, birthdatePerson_3, birthdatePerson_4, birthdatePerson_5]
      if (!insuredPersons) {
        return []
      }
      result = result.slice(0, insuredPersons - 1)
      return result.filter((p: any) => p)
    }

    const formated = {
      insuranceMotive: insuranceMotive ? insuranceMotive.code : null,
      birthdate: birthdatePerson_1 || null,
      insuredPersons: insuredPersons || 0,
      paymentPeriod: paymentPeriod.code || 0,
      personsBirthdate: persons(),
      personsRelation: relations()
    }
    return formated
  }
}

const housekeeperJourney: any = {
  mapJourneyDetailsFromAPIData: (data: Object) => {
    return [
      {
        title: 'Seguro',
        value: 'Seguro de Empregada Doméstica',
        resume: true
      },
      {
        title: 'Seguradora',
        value: get(data, 'package.provider.name'),
        resume: true
      },
      {
        title: 'Nome do Produto',
        value: get(data, 'package.productName'),
        resume: true
      }
    ]
  },
  mapJourneyAPIDataToPanel: (data: Object) => {
    const { insuranceMotive, paymentPeriod, jobType, weeklyHours, hourPay, salary, transportation } = data

    const insuranceMotiveOptions = [
      { description: 'Tenho uma empregada nova', code: 'NEWHOUSEKEEPER' },
      { description: 'Quero comparar com o seguro que tenho de momento', code: 'COMPARE' },
      { description: 'O meu seguro atual vai renovar em breve', code: 'RENEWSOON' },
      { description: 'Porque me foi recomendado', code: 'RECOMMENDED' }
    ]

    const paymentPeriodOptions = [
      { description: 'Anual', code: 1 },
      { description: 'Mensal', code: 12 },
      { description: 'Trimestral', code: 4 },
      { description: 'Semestral', code: 2 }
    ]

    const jobTypeOptions = [
      { description: 'A tempo parcial', code: 'PARTIALTIME' },
      { description: 'A tempo inteiro', code: 'FULLTIME' }
    ]

    const transportationOptions = [
      { description: 'Transporte público', code: 'PUBLIC' },
      { description: 'Carro', code: 'CAR' },
      { description: 'Moto', code: 'BIKE' }
    ]

    const formated = {
      insuranceMotive: insuranceMotive ? insuranceMotiveOptions.find((im: any) => im.code === insuranceMotive) : null,
      paymentPeriod: paymentPeriod ? paymentPeriodOptions.find((im: any) => im.code === paymentPeriod) : null,
      jobType: jobType ? jobTypeOptions.find((im: any) => im.code === jobType) : null,
      weeklyHours: toString(weeklyHours),
      hourPay: toString(hourPay),
      salary: toString(salary),
      transportation: transportation ? transportationOptions.find((im: any) => im.code === transportation) : null
    }
    return formated
  },
  mapJourneyPanelToAPIData: (data: Object) => {
    const { insuranceMotive, paymentPeriod, jobType, weeklyHours, hourPay, salary, transportation } = data

    const formated = {
      insuranceMotive: insuranceMotive ? insuranceMotive.code : null,
      paymentPeriod: paymentPeriod ? paymentPeriod.code : 1,
      jobType: jobType ? jobType.code : null,
      weeklyHours: toString(weeklyHours),
      hourPay: toString(hourPay),
      salary: toString(salary),
      transportation: transportation ? transportation.code : null
    }
    return formated
  }
}

const lifeJourney: any = {
  options: {
    extras: [
      { code: 'MEDICAL_EXPENSES', description: 'Reembolso de Despesas Médicas' },
      { code: 'HOSPITALIZATION', description: 'Subsidio por Hospitalização' },
      { code: 'TEMPORARY_DISABILITY', description: 'Subsidio por Incapacidade Temporária' }
    ],

    about: [
      { code: 'SELF_EMPLOYEED', description: 'Trabalho por conta própria' },
      { code: 'RISK_SPORTS', description: 'Pratico desportos aventura e/ou radicais' },
      { code: 'HAS_MOTOCYCLE', description: 'Tenho moto com 250cc ou superior' },
      { code: 'WORK_TRAVEL', description: 'Viajo bastante a trabalho' },
      { code: 'DANGEROUS_JOB', description: 'O meu trabalho é "perigoso"' }
    ],
    additionalCover: [
      { code: 'INV_TOTAL_PERM', description: 'Invalidez Total e Permanente' },
      { code: 'INV_ABSOLUTE_DEFINITIVE', description: 'Invalidez Absoluta e Definitiva' }
      //{ code: 'DEATH_BY_ACCIDENT', description: 'Morte por Acidente de Circulação' }
    ],
    portugueseResident: [
      { code: 'MORETHANSIX', description: `Mais de 6 meses por ano` },
      { code: 'LESSTHANSIX', description: `Menos de 6 meses por ano` }
    ],
    nif: [{ code: true, description: 'Sim' }, { code: false, description: 'Não' }],
    underageDependents: [{ code: true, description: 'Sim' }, { code: false, description: 'Não' }],
    family: [
      { code: 'JUSTME', description: 'Sou apenas eu' },
      { code: 'TWO', description: 'Somos dois' },
      { code: 'MORETHANTWO', description: 'Somos mais que dois' }
    ],
    insuranceMotive: [
      { code: 'RECOMMENDED', description: 'Porque me foi recomendado' },
      { code: 'COMPARE', description: 'Quero comparar com o seguro que tenho atualmente' },
      { code: 'PROTECTION', description: 'Para estar protegido(a) caso algo aconteça' },
      { code: 'RENEWSOON', description: 'O meu seguro vai renovar-se em breve' }
    ],
    underage: [{ code: true, description: 'Sim' }, { code: false, description: 'Não' }]
  },
  getOptionsByKey: (key: string) => {
    return lifeJourney.options[key]
  },
  getKeyOptionDescriptionByCode: (key: string, code: string) => {
    const options = lifeJourney.getOptionsByKey(key)
    const option = options && options.length > 0 ? options.find((item: Object) => item.code === code) : null
    return option ? option.description : ''
  },
  mapJourneyAPIDataToPanel: (data: Object) => {
    const {
      intendedSumAssured,
      intendedSum,
      detailedProfession,
      nif,
      underageDependents,
      extras = [],
      additionalCover = [],
      age,
      birthdate,
      profession,
      about = [],
      contactNumber,
      portugueseResident,
      family,
      insuranceMotive
    }: any = data

    return {
      nif: {
        code: nif,
        description: lifeJourney.getKeyOptionDescriptionByCode('nif', nif)
      },
      underageDependents: {
        code: underageDependents,
        description: lifeJourney.getKeyOptionDescriptionByCode('underageDependents', underageDependents)
      },
      extras: extras
        ? extras.map((activity: Object) => ({
            code: activity,
            description: lifeJourney.getKeyOptionDescriptionByCode('extras', activity) || 'Nenhuma'
          }))
        : [],
      additionalCover:
        additionalCover && additionalCover[0]
          ? [
              { code: 'MAX', description: 'Sim, proteção máxima' },
              { code: 'MED', description: 'Sim, proteção média' },
              { code: 'MIN', description: 'Sim, proteção minima' },
              { code: 'NONE', description: 'Não é necessário' }
            ].find((item: any) => item.code === additionalCover[0])
          : null,
      age,
      contactNumber,
      birthdate,
      profession,
      portugueseResident: {
        code: portugueseResident,
        description: lifeJourney.getKeyOptionDescriptionByCode('portugueseResident', portugueseResident)
      },
      intendedSumAssured,
      intendedSum,
      about: about
        ? about.map((value: string) => ({
            code: value,
            description: lifeJourney.getKeyOptionDescriptionByCode('about', value) || 'Nenhuma'
          }))
        : [],
      family: {
        code: family,
        description: lifeJourney.getKeyOptionDescriptionByCode('family', family)
      },
      insuranceMotive: {
        code: insuranceMotive,
        description: lifeJourney.getKeyOptionDescriptionByCode('insuranceMotive', insuranceMotive)
      },
      detailedProfession: detailedProfession || null
    }
  },
  mapJourneyPanelToAPIData: (data: Object) => {
    const {
      additionalCover,
      nif,
      underageDependents,
      extras,
      age,
      birthdate,
      contactNumber,
      profession,
      intendedSumAssured,
      intendedSum,
      about,
      portugueseResident,
      family,
      insuranceMotive,
      detailedProfession
    } = data

    const formated = {
      detailedProfession: detailedProfession || null,
      insuranceMotive: insuranceMotive ? insuranceMotive.code : null,
      intendedSum: intendedSum || null,
      additionalCover: additionalCover ? additionalCover.code : 'NONE',
      nif: nif ? nif.code : null,
      contactNumber: contactNumber ? contactNumber : null,
      underageDependents: underageDependents ? underageDependents.code : null,
      extras: extras ? extras.map((extras: Object) => extras.code) : [],
      age: age || null,
      birthdate: birthdate || null,
      profession: profession || null,
      intendedSumAssured: intendedSumAssured || null,
      portugueseResident: portugueseResident ? portugueseResident.code : null,
      about: about ? about.map((value: Object) => value.code) : [],
      family: family ? family.code : null
    }
    return formated
  },
  mapJourneyDetailsFromAPIData: (data: Object) => {
    return [
      {
        title: 'Seguro',
        value: 'Seguro Vida',
        resume: true
      },
      {
        title: 'Seguradora',
        value: get(data, 'package.provider.name'),
        resume: true
      },
      {
        title: 'Nome do Produto',
        value: get(data, 'package.productName'),
        resume: true
      }
    ]
  }
}

const casaJourney: any = {
  options: {
    extras: [
      { code: 'MEDICAL_EXPENSES', description: 'Reembolso de Despesas Médicas' },
      { code: 'HOSPITALIZATION', description: 'Subsidio por Hospitalização' },
      { code: 'TEMPORARY_DISABILITY', description: 'Subsidio por Incapacidade Temporária' }
    ],

    about: [
      { code: 'SELF_EMPLOYEED', description: 'Trabalho por conta própria' },
      { code: 'RISK_SPORTS', description: 'Pratico desportos aventura e/ou radicais' },
      { code: 'HAS_MOTOCYCLE', description: 'Tenho moto com 250cc ou superior' },
      { code: 'WORK_TRAVEL', description: 'Viajo bastante a trabalho' },
      { code: 'DANGEROUS_JOB', description: 'O meu trabalho é "perigoso"' }
    ],
    additionalCover: [
      { code: 'INV_TOTAL_PERM', description: 'Invalidez Total e Permanente' },
      { code: 'INV_ABSOLUTE_DEFINITIVE', description: 'Invalidez Absoluta e Definitiva' }
      //{ code: 'DEATH_BY_ACCIDENT', description: 'Morte por Acidente de Circulação' }
    ],
    portugueseResident: [
      { code: 'MORETHANSIX', description: `Mais de 6 meses por ano` },
      { code: 'LESSTHANSIX', description: `Menos de 6 meses por ano` }
    ],
    nif: [{ code: true, description: 'Sim' }, { code: false, description: 'Não' }],
    underageDependents: [{ code: true, description: 'Sim' }, { code: false, description: 'Não' }],
    family: [
      { code: 'JUSTME', description: 'Sou apenas eu' },
      { code: 'TWO', description: 'Somos dois' },
      { code: 'MORETHANTWO', description: 'Somos mais que dois' }
    ],
    insuranceMotive: [
      { code: 'RECOMMENDED', description: 'Porque me foi recomendado' },
      { code: 'COMPARE', description: 'Quero comparar com o seguro que tenho atualmente' },
      { code: 'PROTECTION', description: 'Para estar protegido(a) caso algo aconteça' },
      { code: 'RENEWSOON', description: 'O meu seguro vai renovar-se em breve' }
    ],
    underage: [{ code: true, description: 'Sim' }, { code: false, description: 'Não' }]
  },
  getOptionsByKey: (key: string) => {
    return lifeJourney.options[key]
  },
  getKeyOptionDescriptionByCode: (key: string, code: string) => {
    const options = lifeJourney.getOptionsByKey(key)
    const option = options && options.length > 0 ? options.find((item: Object) => item.code === code) : null
    return option ? option.description : ''
  },
  mapJourneyAPIDataToPanel: (data: Object) => {
    const {
      intendedSumAssured,
      intendedSum,
      detailedProfession,
      nif,
      underageDependents,
      extras = [],
      additionalCover = [],
      age,
      birthdate,
      profession,
      about = [],
      portugueseResident,
      family,
      insuranceMotive
    }: any = data

    return {
      nif: {
        code: nif,
        description: lifeJourney.getKeyOptionDescriptionByCode('nif', nif)
      },
      underageDependents: {
        code: underageDependents,
        description: lifeJourney.getKeyOptionDescriptionByCode('underageDependents', underageDependents)
      },
      extras: extras
        ? extras.map((activity: Object) => ({
            code: activity,
            description: lifeJourney.getKeyOptionDescriptionByCode('extras', activity) || 'Nenhuma'
          }))
        : [],
      additionalCover:
        additionalCover && additionalCover[0]
          ? [
              { code: 'MAX', description: 'Sim, proteção máxima' },
              { code: 'MED', description: 'Sim, proteção média' },
              { code: 'MIN', description: 'Sim, proteção minima' },
              { code: 'NONE', description: 'Não é necessário' }
            ].find((item: any) => item.code === additionalCover[0])
          : null,
      age,
      birthdate,
      profession,
      portugueseResident: {
        code: portugueseResident,
        description: lifeJourney.getKeyOptionDescriptionByCode('portugueseResident', portugueseResident)
      },
      intendedSumAssured,
      intendedSum,
      about: about
        ? about.map((value: string) => ({
            code: value,
            description: lifeJourney.getKeyOptionDescriptionByCode('about', value) || 'Nenhuma'
          }))
        : [],
      family: {
        code: family,
        description: lifeJourney.getKeyOptionDescriptionByCode('family', family)
      },
      insuranceMotive: {
        code: insuranceMotive,
        description: lifeJourney.getKeyOptionDescriptionByCode('insuranceMotive', insuranceMotive)
      },
      detailedProfession: detailedProfession || null
    }
  },
  mapJourneyPanelToAPIData: (data: Object) => {
    const {
      additionalCover,
      nif,
      underageDependents,
      extras,
      age,
      birthdate,
      profession,
      intendedSumAssured,
      intendedSum,
      about,
      portugueseResident,
      family,
      insuranceMotive,
      detailedProfession
    } = data

    const formated = {
      detailedProfession: detailedProfession || null,
      insuranceMotive: insuranceMotive ? insuranceMotive.code : null,
      intendedSum: intendedSum || null,
      additionalCover: additionalCover ? additionalCover.code : 'NONE',
      nif: nif ? nif.code : null,
      underageDependents: underageDependents ? underageDependents.code : null,
      extras: extras ? extras.map((extras: Object) => extras.code) : [],
      age: age || null,
      birthdate: birthdate || null,
      profession: profession || null,
      intendedSumAssured: intendedSumAssured || null,
      portugueseResident: portugueseResident ? portugueseResident.code : null,
      about: about ? about.map((value: Object) => value.code) : [],
      family: family ? family.code : null
    }
    return formated
  },
  mapJourneyDetailsFromAPIData: (data: Object) => {
    return [
      {
        title: 'Seguro',
        value: 'Seguro Casa (Multirriscos)',
        resume: true
      },
      {
        title: 'Seguradora',
        value: get(data, 'package.provider.name'),
        resume: true
      },
      {
        title: 'Nome do Produto',
        value: get(data, 'package.productName'),
        resume: true
      }
    ]
  }
}

const personalJourney: any = {
  options: {
    extras: [
      { code: 'MEDICAL_EXPENSES', description: 'Reembolso de Despesas Médicas' },
      { code: 'HOSPITALIZATION', description: 'Subsidio por Hospitalização' },
      { code: 'TEMPORARY_DISABILITY', description: 'Subsidio por Incapacidade Temporária' }
    ],
    about: [
      { code: 'PROFESSIONAL', description: 'Profissionais' },
      { code: 'EXTRA_PROFESSIONAL', description: 'Extra-profissionais' },
      { code: 'BOTH', description: 'Ambas' }
    ],
    additionalCover: [
      { code: 'INV_TOTAL_PERM', description: 'Invalidez Total e Permanente' },
      { code: 'INV_ABSOLUTE_DEFINITIVE', description: 'Invalidez Absoluta e Definitiva' }
      //{ code: 'DEATH_BY_ACCIDENT', description: 'Morte por Acidente de Circulação' }
    ],
    portugueseResident: [
      { code: 'MORETHANSIX', description: `Mais de 6 meses por ano` },
      { code: 'LESSTHANSIX', description: `Menos de 6 meses por ano` }
    ],
    nif: [{ code: true, description: 'Sim' }, { code: false, description: 'Não' }],
    underageDependents: [{ code: true, description: 'Sim' }, { code: false, description: 'Não' }],
    family: [
      { code: 'JUSTME', description: 'Sou apenas eu' },
      { code: 'TWO', description: 'Somos dois' },
      { code: 'MORETHANTWO', description: 'Somos mais que dois' }
    ],
    insuranceMotive: [
      { code: 'RECOMMENDED', description: 'Porque me foi recomendado' },
      { code: 'COMPARE', description: 'Quero comparar com o seguro que tenho atualmente' },
      { code: 'PROTECTION', description: 'Para estar protegido(a) caso algo aconteça' },
      { code: 'RENEWSOON', description: 'O meu seguro vai renovar-se em breve' }
    ],
    underage: [{ code: true, description: 'Sim' }, { code: false, description: 'Não' }]
  },
  getOptionsByKey: (key: string) => {
    return personalJourney.options[key]
  },
  getKeyOptionDescriptionByCode: (key: string, code: string) => {
    const options = personalJourney.getOptionsByKey(key)
    const option = options && options.length > 0 ? options.find((item: Object) => item.code === code) : null
    return option ? option.description : ''
  },
  mapJourneyAPIDataToPanel: (data: Object) => {
    const {
      intendedSumAssured,
      intendedSum,
      nif,
      underageDependents,
      extras = [],
      additionalCover = [],
      age,
      birthdate,
      profession,
      detailedProfession,
      about = [],
      portugueseResident,
      family,
      insuranceMotive,
      practiseSport,
      sport
    }: any = data

    const transformPracticeSport = (practiseSport: string) => {
      if (practiseSport) {
        return { code: true, description: 'Sim' }
      }

      return { code: false, description: 'Não' }
    }

    return {
      practiseSport: transformPracticeSport(practiseSport),
      sport: practiseSport,
      nif: {
        code: nif,
        description: personalJourney.getKeyOptionDescriptionByCode('nif', nif)
      },
      underageDependents: {
        code: underageDependents,
        description: personalJourney.getKeyOptionDescriptionByCode('underageDependents', underageDependents)
      },
      extras: extras
        ? extras.map((activity: Object) => ({
            code: activity,
            description: personalJourney.getKeyOptionDescriptionByCode('extras', activity) || 'Nenhuma'
          }))
        : [],
      additionalCover: additionalCover
        ? additionalCover.map((value: Object) => ({
            code: value,
            description: personalJourney.getKeyOptionDescriptionByCode('additionalCover', value) || 'Nenhuma'
          }))
        : [],
      age,
      birthdate,
      profession,
      detailedProfession,
      portugueseResident: {
        code: portugueseResident,
        description: personalJourney.getKeyOptionDescriptionByCode('portugueseResident', portugueseResident)
      },
      intendedSumAssured,
      intendedSum,
      about: about
        ? about.map((value: string) => ({
            code: value,
            description: personalJourney.getKeyOptionDescriptionByCode('about', value) || 'Nenhuma'
          }))
        : [],
      family: {
        code: family,
        description: personalJourney.getKeyOptionDescriptionByCode('family', family)
      },
      insuranceMotive: {
        code: insuranceMotive,
        description: personalJourney.getKeyOptionDescriptionByCode('insuranceMotive', insuranceMotive)
      }
    }
  },
  mapJourneyPanelToAPIData: (data: Object) => {
    const {
      additionalCover,
      nif,
      underageDependents,
      extras,
      age,
      birthdate,
      profession,
      intendedSumAssured,
      intendedSum,
      about,
      portugueseResident,
      family,
      insuranceMotive,
      detailedProfession,
      practiseSport,
      sport
    } = data

    const formated = (entity: any) => {
      const code = entity && entity.code ? entity.code : null
      const fObject = entity && entity[0] && entity[0].code ? entity[0].code : null
      return code ? code : fObject
    }

    const parseAbout = (about: any) => {
      if (about && about.length) {
        return about.map((item: any) => item.code)
      }
      return []
    }
    const formatedResponse = {
      nif: formated(nif) || null,
      underageDependents: formated(underageDependents) || null,
      extras: extras && extras.length ? extras.map((item: any) => item.code) : null,
      portugueseResident: formated(portugueseResident) || null,
      additionalCover: formated(additionalCover) || null,
      about: parseAbout(about),
      insuranceMotive: formated(insuranceMotive),
      age,
      birthdate,
      profession,
      detailedProfession,
      intendedSumAssured: typeof intendedSumAssured === 'string' ? Number(intendedSumAssured) : intendedSumAssured,
      intendedSum: typeof intendedSum === 'string' ? Number(intendedSum) : intendedSum,
      practiseSport: practiseSport && practiseSport.code ? sport : null
    }

    return formatedResponse
  },
  mapJourneyDetailsFromAPIData: (data: Object) => {
    return [
      {
        title: 'Seguro',
        value: 'Acidentes Pessoais',
        resume: true
      },
      {
        title: 'Seguradora',
        value: get(data, 'package.provider.name'),
        resume: true
      },
      {
        title: 'Nome do Produto',
        value: get(data, 'package.productName'),
        resume: true
      }
    ]
  }
}

const personalExtra: any = {
  options: {
    beneficiaryType: [
      { code: 'LEGAL_PARTNER', description: 'Conjuge' },
      { code: 'LEGAL_HEIRS', description: 'Herdeiros Legais, em partes iguais' },
      { code: 'OTHER', description: 'Nomear uma pessoa diferente' },
      { code: 'OTHER_EQUAL_PARTS', description: 'Nomear duas pessoas, em partes iguais' }
    ]
  },
  getOptionsByKey: (key: string) => {
    return personalExtra.options[key]
  },
  getKeyOptionDescriptionByCode: (key: string, code: string) => {
    const options = personalExtra.getOptionsByKey(key)
    const option = options && options.length > 0 ? options.find((item: Object) => item.code === code) : null
    return option ? option.description : ''
  },
  mapJourneyAPIDataToPanel: (data: Object) => {
    const { extras = [], age, beneficiaryType, family }: any = data

    return {
      extras: extras
        ? extras.map((activity: string) => ({
            code: activity,
            description: personalExtra.getKeyOptionDescriptionByCode('extras', activity)
          }))
        : [],
      age,
      beneficiaryType: {
        code: beneficiaryType,
        description: personalExtra.getKeyOptionDescriptionByCode('beneficiaryType', beneficiaryType)
      },
      family: {
        code: family,
        description: personalExtra.getKeyOptionDescriptionByCode('family', family)
      }
    }
  },
  mapJourneyPanelToAPIData: (data: Object) => {
    const { extras, age, intendedSumAssured, beneficiaryType } = data

    return {
      extras: extras.map((extras: Object) => extras.code),
      age,
      intendedSumAssured,
      beneficiaryType: beneficiaryType.code
    }
  },
  mapJourneyDetailsFromAPIData: (data: Object) => {
    const { age, intendedSumAssured, beneficiaryType, extras } = data || {}

    return [
      {
        title: 'Idade do segurado',
        value: age
      },
      {
        title: 'Valor a receber',
        value: intendedSumAssured
      },
      {
        title: 'Em caso de Morte, a quem deve a Seguradora pagar?',
        value: beneficiaryType.description
      },
      {
        value: extras
          ? extras
              .map((activity: Object) => {
                const foundExtra =
                  personalExtra.options.extras.find((extras: Object) => extras.code === activity).description || {}

                return foundExtra ? foundExtra.description : ''
              })
              .join(', ')
          : 'Nenhuma'
      }
    ]
  }
}

const scooterJourney: any = {
  options: {
    extras: [
      { code: 'MEDICAL_EXPENSES', description: 'Reembolso de Despesas Médicas' },
      { code: 'HOSPITALIZATION', description: 'Subsidio por Hospitalização' },
      { code: 'TEMPORARY_DISABILITY', description: 'Subsidio por Incapacidade Temporária' }
    ],
    about: [
      { code: 'PROFESSIONAL', description: 'Profissionais' },
      { code: 'EXTRA_PROFESSIONAL', description: 'Extra-profissionais' },
      { code: 'BOTH', description: 'Ambas' }
    ],
    additionalCover: [
      { code: 'INV_TOTAL_PERM', description: 'Invalidez Total e Permanente' },
      { code: 'INV_ABSOLUTE_DEFINITIVE', description: 'Invalidez Absoluta e Definitiva' }
      //{ code: 'DEATH_BY_ACCIDENT', description: 'Morte por Acidente de Circulação' }
    ],
    portugueseResident: [
      { code: 'MORETHANSIX', description: `Mais de 6 meses por ano` },
      { code: 'LESSTHANSIX', description: `Menos de 6 meses por ano` }
    ],
    nif: [{ code: true, description: 'Sim' }, { code: false, description: 'Não' }],
    underageDependents: [{ code: true, description: 'Sim' }, { code: false, description: 'Não' }],
    family: [
      { code: 'JUSTME', description: 'Sou apenas eu' },
      { code: 'TWO', description: 'Somos dois' },
      { code: 'MORETHANTWO', description: 'Somos mais que dois' }
    ],
    insuranceMotive: [
      { code: 'RECOMMENDED', description: 'Porque me foi recomendado' },
      { code: 'COMPARE', description: 'Quero comparar com o seguro que tenho atualmente' },
      { code: 'PROTECTION', description: 'Para estar protegido(a) caso algo aconteça' },
      { code: 'RENEWSOON', description: 'O meu seguro vai renovar-se em breve' }
    ],
    underage: [{ code: true, description: 'Sim' }, { code: false, description: 'Não' }]
  },
  getOptionsByKey: (key: string) => {
    return personalJourney.options[key]
  },
  getKeyOptionDescriptionByCode: (key: string, code: string) => {
    const options = personalJourney.getOptionsByKey(key)
    const option = options && options.length > 0 ? options.find((item: Object) => item.code === code) : null
    return option ? option.description : ''
  },
  mapJourneyAPIDataToPanel: (data: Object) => {
    const {
      intendedSumAssured,
      intendedSum,
      nif,
      underageDependents,
      extras = [],
      additionalCover = [],
      age,
      birthdate,
      profession,
      detailedProfession,
      about = [],
      portugueseResident,
      family,
      insuranceMotive,
      practiseSport,
      sport
    }: any = data

    const transformPracticeSport = (practiseSport: string) => {
      if (practiseSport) {
        return { code: true, description: 'Sim' }
      }

      return { code: false, description: 'Não' }
    }

    return {
      practiseSport: transformPracticeSport(practiseSport),
      sport: practiseSport,
      nif: {
        code: nif,
        description: personalJourney.getKeyOptionDescriptionByCode('nif', nif)
      },
      underageDependents: {
        code: underageDependents,
        description: personalJourney.getKeyOptionDescriptionByCode('underageDependents', underageDependents)
      },
      extras: extras
        ? extras.map((activity: Object) => ({
            code: activity,
            description: personalJourney.getKeyOptionDescriptionByCode('extras', activity) || 'Nenhuma'
          }))
        : [],
      additionalCover: additionalCover
        ? additionalCover.map((value: Object) => ({
            code: value,
            description: personalJourney.getKeyOptionDescriptionByCode('additionalCover', value) || 'Nenhuma'
          }))
        : [],
      age,
      birthdate,
      profession,
      detailedProfession,
      portugueseResident: {
        code: portugueseResident,
        description: personalJourney.getKeyOptionDescriptionByCode('portugueseResident', portugueseResident)
      },
      intendedSumAssured,
      intendedSum,
      about: about
        ? about.map((value: string) => ({
            code: value,
            description: personalJourney.getKeyOptionDescriptionByCode('about', value) || 'Nenhuma'
          }))
        : [],
      family: {
        code: family,
        description: personalJourney.getKeyOptionDescriptionByCode('family', family)
      },
      insuranceMotive: {
        code: insuranceMotive,
        description: personalJourney.getKeyOptionDescriptionByCode('insuranceMotive', insuranceMotive)
      }
    }
  },
  mapJourneyPanelToAPIData: (data: Object) => {
    const {
      additionalCover,
      nif,
      underageDependents,
      extras,
      age,
      birthdate,
      profession,
      intendedSumAssured,
      intendedSum,
      about,
      portugueseResident,
      family,
      insuranceMotive,
      detailedProfession,
      practiseSport,
      sport
    } = data

    const formated = (entity: any) => {
      const code = entity && entity.code ? entity.code : null
      const fObject = entity && entity[0] && entity[0].code ? entity[0].code : null
      return code ? code : fObject
    }

    const parseAbout = (about: any) => {
      if (about && about.length) {
        return about.map((item: any) => item.code)
      }
      return []
    }
    const formatedResponse = {
      nif: formated(nif) || null,
      underageDependents: formated(underageDependents) || null,
      extras: extras && extras.length ? extras.map((item: any) => item.code) : null,
      portugueseResident: formated(portugueseResident) || null,
      additionalCover: formated(additionalCover) || null,
      about: parseAbout(about),
      insuranceMotive: formated(insuranceMotive),
      age,
      birthdate,
      profession,
      detailedProfession,
      intendedSumAssured: typeof intendedSumAssured === 'string' ? Number(intendedSumAssured) : intendedSumAssured,
      intendedSum: typeof intendedSum === 'string' ? Number(intendedSum) : intendedSum,
      practiseSport: practiseSport && practiseSport.code ? sport : null
    }

    return formatedResponse
  },
  mapJourneyDetailsFromAPIData: (data: Object) => {
    return [
      {
        title: 'Seguro',
        value: 'Trotinete',
        resume: true
      },
      {
        title: 'Seguradora',
        value: get(data, 'package.provider.name'),
        resume: true
      },
      {
        title: 'Nome do Produto',
        value: get(data, 'package.productName'),
        resume: true
      }
    ]
  }
}

const scooterExtra: any = {
  options: {
    beneficiaryType: [
      { code: 'LEGAL_PARTNER', description: 'Conjuge' },
      { code: 'LEGAL_HEIRS', description: 'Herdeiros Legais, em partes iguais' },
      { code: 'OTHER', description: 'Nomear uma pessoa diferente' },
      { code: 'OTHER_EQUAL_PARTS', description: 'Nomear duas pessoas, em partes iguais' }
    ]
  },
  getOptionsByKey: (key: string) => {
    return personalExtra.options[key]
  },
  getKeyOptionDescriptionByCode: (key: string, code: string) => {
    const options = personalExtra.getOptionsByKey(key)
    const option = options && options.length > 0 ? options.find((item: Object) => item.code === code) : null
    return option ? option.description : ''
  },
  mapJourneyAPIDataToPanel: (data: Object) => {
    const { extras = [], age, beneficiaryType, family }: any = data

    return {
      extras: extras
        ? extras.map((activity: string) => ({
            code: activity,
            description: personalExtra.getKeyOptionDescriptionByCode('extras', activity)
          }))
        : [],
      age,
      beneficiaryType: {
        code: beneficiaryType,
        description: personalExtra.getKeyOptionDescriptionByCode('beneficiaryType', beneficiaryType)
      },
      family: {
        code: family,
        description: personalExtra.getKeyOptionDescriptionByCode('family', family)
      }
    }
  },
  mapJourneyPanelToAPIData: (data: Object) => {
    const { extras, age, intendedSumAssured, beneficiaryType } = data

    return {
      extras: extras.map((extras: Object) => extras.code),
      age,
      intendedSumAssured,
      beneficiaryType: beneficiaryType.code
    }
  },
  mapJourneyDetailsFromAPIData: (data: Object) => {
    const { age, intendedSumAssured, beneficiaryType, extras } = data || {}

    return [
      {
        title: 'Idade do segurado',
        value: age
      },
      {
        title: 'Valor a receber',
        value: intendedSumAssured
      },
      {
        title: 'Em caso de Morte, a quem deve a Seguradora pagar?',
        value: beneficiaryType.description
      },
      {
        value: extras
          ? extras
              .map((activity: Object) => {
                const foundExtra =
                  personalExtra.options.extras.find((extras: Object) => extras.code === activity).description || {}

                return foundExtra ? foundExtra.description : ''
              })
              .join(', ')
          : 'Nenhuma'
      }
    ]
  }
}

const mapJourneyTypeToPath = (type: string) => {
  switch (type) {
    case 'TRAVEL':
      return 'seguro-viagem'
    case 'PERSONALACCIDENT':
      return 'seguro-acidentes-pessoais'
    case 'LIFE':
      return 'seguro-vida'
    case 'BIKE':
      return 'seguro-bicicleta'
    case 'HEALTH':
      return 'seguro-saude'
    case 'CHILD':
      return 'seguro-crianca'
    case 'DENTAL':
      return 'seguro-dental'
    case 'HOUSEKEEPER':
      return 'seguro-empregada'
    case 'CAR':
      return 'seguro-carro'
    case 'SCOOTER':
      return 'seguro-trotinete'
    case 'HOME':
      return 'seguro-casa'
    case 'MORTGAGE':
      return 'seguro-vida-credito'
    default:
      return ''
  }
}
export {
  mapJourneyTypeToPath,
  travelJourney,
  personalJourney,
  lifeJourney,
  personalExtra,
  scooterExtra,
  bikeJourney,
  childJourney,
  housekeeperJourney,
  scooterJourney,
  casaJourney
}
