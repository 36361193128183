import gql from 'graphql-tag'

export const ADD_ORDER = gql`
  mutation orderAdd($uuidJourney: ID!, $uuidPrice: ID!, $orderType: OrderType) {
    orderAdd(input: { uuidJourney: $uuidJourney, uuidPrice: $uuidPrice, orderType: $orderType }) {
      uuid
    }
  }
`
export const INSTA_ORDER_ADD = gql`
  mutation instaOrderAdd($uuidJourney: ID!, $uuidPackage: ID, $orderType: OrderType, $price: String) {
    instaOrderAdd(
      input: { uuidJourney: $uuidJourney, uuidPackage: $uuidPackage, orderType: $orderType, price: $price }
    ) {
      uuid
      uuidJourney
    }
  }
`

export const ADD_ORDER_BIKE = gql`
  mutation instaOrderAdd($uuidPackage: ID!, $orderType: OrderType) {
    instaOrderAdd(input: { uuidPackage: $uuidPackage, orderType: $orderType }) {
      uuid
    }
  }
`
export const ADD_ORDER_CRITICALILLNESS = gql`
  mutation instaOrderAdd($uuidJourney: ID!, $uuidPackage: ID, $orderType: OrderType, $price: String) {
    instaOrderAdd(
      input: { uuidJourney: $uuidJourney, uuidPackage: $uuidPackage, orderType: $orderType, price: $price }
    ) {
      uuid
    }
  }
`
export const ADD_ORDER_HOSPITALIZATION = gql`
  mutation orderAdd($uuidJourney: ID!, $uuidPackage: ID, $orderType: OrderType, $price: String) {
    orderAdd(input: { uuidJourney: $uuidJourney, uuidPackage: $uuidPackage, orderType: $orderType, price: $price }) {
      uuid
    }
  }
`
export const ADD_ORDER_CHILD = gql`
  mutation instaOrderAdd($uuidPackage: ID!, $orderType: OrderType) {
    instaOrderAdd(input: { uuidPackage: $uuidPackage, orderType: $orderType }) {
      uuid
    }
  }
`
export const ADD_ORDER_SCOOTER = gql`
  mutation instaOrderAdd($uuidPackage: ID!, $orderType: OrderType) {
    instaOrderAdd(input: { uuidPackage: $uuidPackage, orderType: $orderType }) {
      uuid
    }
  }
`

export const ADD_ORDER_LIFE = gql`
  mutation instaOrderAdd($uuidJourney: ID!, $uuidPackage: ID, $orderType: OrderType, $price: String) {
    instaOrderAdd(
      input: { uuidJourney: $uuidJourney, uuidPackage: $uuidPackage, orderType: $orderType, price: $price }
    ) {
      uuid
    }
  }
`
export const ADD_ORDER_HEALTH = gql`
  mutation orderAdd($uuidJourney: ID!, $uuidPackage: ID, $orderType: OrderType, $price: String) {
    orderAdd(input: { uuidJourney: $uuidJourney, uuidPackage: $uuidPackage, orderType: $orderType, price: $price }) {
      uuid
    }
  }
`

export const ADD_ORDER_DENTAL = gql`
  mutation orderAdd($uuidJourney: ID!, $uuidPackage: ID, $orderType: OrderType, $price: String) {
    orderAdd(input: { uuidJourney: $uuidJourney, uuidPackage: $uuidPackage, orderType: $orderType, price: $price }) {
      uuid
    }
  }
`

export const ADD_ORDER_TRAVEL = gql`
  mutation orderAdd($uuidJourney: ID!, $orderType: OrderType, $uuidPrice: ID) {
    orderAdd(input: { uuidJourney: $uuidJourney, orderType: $orderType, uuidPrice: $uuidPrice }) {
      uuid
    }
  }
`

export const ADD_ORDER_HOUSEKEEPER = gql`
  mutation orderAdd($uuidJourney: ID!, $uuidPackage: ID, $orderType: OrderType, $price: String) {
    orderAdd(input: { uuidJourney: $uuidJourney, uuidPackage: $uuidPackage, orderType: $orderType, price: $price }) {
      uuid
    }
  }
`
export const ADD_ORDER_CAR = gql`
  mutation orderAdd($uuidJourney: ID!, $uuidPackage: ID, $orderType: OrderType, $price: String) {
    orderAdd(input: { uuidJourney: $uuidJourney, uuidPackage: $uuidPackage, orderType: $orderType, price: $price }) {
      uuid
    }
  }
`
export const INSTA_ADD_ORDER_CAR = gql`
  mutation instaOrderAdd($uuidJourney: ID!, $uuidPackage: ID, $orderType: OrderType, $price: String) {
    instaOrderAdd(
      input: { uuidJourney: $uuidJourney, uuidPackage: $uuidPackage, orderType: $orderType, price: $price }
    ) {
      uuid
    }
  }
`
export const ADD_ORDER_PERSONALACCIDENT = gql`
  mutation orderAdd($uuidJourney: ID!, $uuidPackage: ID, $orderType: OrderType, $price: String) {
    orderAdd(input: { uuidJourney: $uuidJourney, uuidPackage: $uuidPackage, orderType: $orderType, price: $price }) {
      uuid
    }
  }
`

export const ADD_ORDER_MORTGAGE = gql`
  mutation orderAdd($uuidJourney: ID!, $uuidPackage: ID, $orderType: OrderType, $price: String) {
    orderAdd(input: { uuidJourney: $uuidJourney, uuidPackage: $uuidPackage, orderType: $orderType, price: $price }) {
      uuid
    }
  }
`

export const ORDER_PAY = gql`
  mutation orderPay(
    $uuid: ID!
    $paymentPeriod: PaymentPeriodInput!
    $paymentType: PaymentType!
    $name: String!
    $email: String!
    $accountHolder: String
    $iban: String
    $phone: String
    $phoneIndicative: String
  ) {
    orderPay(
      input: {
        uuid: $uuid
        paymentPeriod: $paymentPeriod
        paymentType: $paymentType
        name: $name
        email: $email
        accountHolder: $accountHolder
        iban: $iban
        phone: $phone
        phoneIndicative: $phoneIndicative
      }
    ) {
      type
      status
      url
      message
      value
      entity
      reference
      alias
      extra {
        url
        redirect
        includesSign
      }
    }
  }
`

export const INSTA_ORDER_PAY_NEW = gql`
  mutation InstaOrderPay($input: OrderPaymentInput) {
    instaOrderPay(input: $input) {
      type
      status
      url
      message
      value
      entity
      reference
      alias
      extra {
        url
        redirect
        includesSign
      }
    }
  }
`

export const INSTA_ORDER_PAY = gql`
  mutation instaOrderPay(
    $uuid: ID!
    $paymentPeriod: PaymentPeriodInput!
    $paymentType: PaymentType!
    $name: String!
    $email: String!
    $accountHolder: String
    $iban: String
    $isBankAccountHolder: Boolean
    $phone: String
    $phoneIndicative: String
    $paymentIntent: String
  ) {
    instaOrderPay(
      input: {
        uuid: $uuid
        paymentPeriod: $paymentPeriod
        paymentType: $paymentType
        name: $name
        email: $email
        accountHolder: $accountHolder
        iban: $iban
        isBankAccountHolder: $isBankAccountHolder
        phone: $phone
        phoneIndicative: $phoneIndicative
        paymentIntent: $paymentIntent
      }
    ) {
      type
      status
      url
      message
      value
      entity
      reference
      alias
      extra {
        url
        redirect
        includesSign
      }
    }
  }
`

export const CONFIRM_CC_PAYMENT = gql`
  mutation confirmCCPayment(
    $authorization: String
    $value: Float
    $entity: Int
    $reference: Int
    $tkey: ID!
    $status: String
  ) {
    confirmCCPayment(
      input: {
        authorization: $authorization
        value: $value
        entity: $entity
        reference: $reference
        tkey: $tkey
        status: $status
      }
    ) {
      uuid
      paymentInfo {
        extra {
          includesSign
          redirect
          url
        }
        status
      }
      orderType
    }
  }
`

export const UPDATE_ORDER = gql`
  mutation orderUpdate(
    $uuidOrder: ID!
    $insurer: PersonInput
    $insuredPersons: [PersonInput]
    $payment: PaymentInput
    $consents: ConsentsInput
    $beneficiaryType: BeneficiaryType
    $beneficiaryPersons: [PersonInput]
    $additionalAttributes: OrderAdditionalAttributesInput
  ) {
    orderUpdate(
      input: {
        uuidOrder: $uuidOrder
        insurer: $insurer
        insuredPersons: $insuredPersons
        payment: $payment
        consents: $consents
        beneficiaryType: $beneficiaryType
        beneficiaryPersons: $beneficiaryPersons
        additionalAttributes: $additionalAttributes
      }
    ) {
      uuid
      additionalAttributes {
        idNumber
        licenseDate
        licenseNumber
        plate
        model
        brand
        version
        extrasValue
        tow
        usage
        creditInstitution
        correspondenceAddress
        correspondencePostalCode
      }
      beneficiaryType
      beneficiaryPersons {
        name
        birthdate
        idNumber
        nif
        postalCode
        relation
      }
    }
  }
`

export const CREATE_CREDIT_CARD_SESSION = gql`
  mutation creditCardPayment(
    $price: String!
    $type: OrderType!
    $successUrl: String!
    $failureUrl: String!
    $orderId: String!
    $userEmail: String!
  ) {
    creditCardPayment(
      input: {
        price: $price
        type: $type
        successUrl: $successUrl
        failureUrl: $failureUrl
        orderId: $orderId
        userEmail: $userEmail
      }
    ) {
      payment_intent
      url
    }
  }
`

/////////car new query  start//

export const UPDATE_ORDER_CAR = gql`
  mutation instaOrderUpdate(
    $uuidOrder: ID!
    $insurer: PersonInput
    $insuredPersons: [PersonInput]
    $payment: PaymentInput
    $consents: ConsentsInput
    $beneficiaryType: BeneficiaryType
    $beneficiaryPersons: [PersonInput]
    $additionalAttributes: OrderAdditionalAttributesInput
    $insuranceStartDate: Date
  ) {
    instaOrderUpdate(
      input: {
        uuidOrder: $uuidOrder
        insurer: $insurer
        insuredPersons: $insuredPersons
        payment: $payment
        consents: $consents
        beneficiaryType: $beneficiaryType
        beneficiaryPersons: $beneficiaryPersons
        additionalAttributes: $additionalAttributes
        insuranceStartDate: $insuranceStartDate
      }
    ) {
      uuid
      additionalAttributes {
        idNumber
        licenseDate
        licenseNumber
        plate
        model
        brand
        version
        extrasValue
        tow
        usage
        creditInstitution
        correspondenceAddress
        correspondencePostalCode
      }
    }
  }
`

///// car end new query/////
export const INSTA_UPDATE_ORDER = gql`
  mutation instaOrderUpdate(
    $uuidOrder: ID!
    $insurer: PersonInput
    $insuredPersons: [PersonInput]
    $payment: PaymentInput
    $consents: ConsentsInput
    $beneficiaryType: BeneficiaryType
    $beneficiaryPersons: [PersonInput]
    $additionalAttributes: OrderAdditionalAttributesInput
    $insuranceStartDate: Date
    $companyId: String
  ) {
    instaOrderUpdate(
      input: {
        uuidOrder: $uuidOrder
        insurer: $insurer
        insuredPersons: $insuredPersons
        payment: $payment
        consents: $consents
        beneficiaryType: $beneficiaryType
        beneficiaryPersons: $beneficiaryPersons
        additionalAttributes: $additionalAttributes
        insuranceStartDate: $insuranceStartDate
        companyId: $companyId
      }
    ) {
      uuid
    }
  }
`

export const ADD_ORDER_PPR = gql`
  mutation instaOrderAdd($uuidJourney: ID!, $uuidPackage: ID!, $orderType: OrderType, $price: String) {
    instaOrderAdd(
      input: { uuidJourney: $uuidJourney, uuidPackage: $uuidPackage, orderType: $orderType, price: $price }
    ) {
      uuid
    }
  }
`

export const ADD_WORK_ACCIDENT_PACKAGE = gql`
  mutation AddWorkAccidentPackage(
    $packageId: ID!
    $uuid: ID!
    $provider: ProviderInput!
    $price: Int!
    $workAccidentId: String!
    $productName: String!
    $insuranceProviderId: Int!
    $exclusion: WorkExclusionInput
    $eligibility: WorkEligibilityInput
    $complementaryInfo: WorkComplementaryInfoInput
  ) {
    addWorkAccidentPackage(
      input: {
        packageId: $packageId
        uuid: $uuid
        provider: $provider
        price: $price
        workAccidentId: $workAccidentId
        productName: $productName
        insuranceProviderId: $insuranceProviderId
        exclusion: $exclusion
        eligibility: $eligibility
        complementaryInfo: $complementaryInfo
      }
    ) {
      id
    }
  }
`

export const ADD_INSTA_ORDER = gql`
  mutation InstaOrderAdd($uuidJourney: ID, $uuidPackage: ID, $orderType: OrderType, $price: String, $uuidPrice: ID) {
    instaOrderAdd(
      input: {
        uuidJourney: $uuidJourney
        uuidPackage: $uuidPackage
        orderType: $orderType
        price: $price
        uuidPrice: $uuidPrice
      }
    ) {
      uuid
    }
  }
`

export const UPDATE_INSTA_ORDER = gql`
  mutation instaOrderUpdate(
    $uuidOrder: ID!
    $insurer: PersonInput
    $insuredPersons: [PersonInput]
    $consents: ConsentsInput
    $insuranceStartDate: Date
    $payment: PaymentInput
  ) {
    instaOrderUpdate(
      input: {
        uuidOrder: $uuidOrder
        insuranceStartDate: $insuranceStartDate
        insurer: $insurer
        insuredPersons: $insuredPersons
        consents: $consents
        payment: $payment
      }
    ) {
      uuid
      insuranceStartDate
    }
  }
`

export const ADD_ORDER_PET = gql`
  mutation InstaOrderAdd($input: OrderInput) {
    instaOrderAdd(input: $input) {
      uuid
      uuidJourney
      uuidPackage
    }
  }
`

export const PET_INSTA_ORDER_UPDATE = gql`
  mutation instaOrderUpdate(
    $uuidOrder: ID!
    $insurer: PersonInput
    $insuredPersons: [PersonInput]
    $consents: ConsentsInput
    $insuranceStartDate: Date
    $insuredPet: PetInput
  ) {
    instaOrderUpdate(
      input: {
        uuidOrder: $uuidOrder
        insuranceStartDate: $insuranceStartDate
        insurer: $insurer
        insuredPersons: $insuredPersons
        consents: $consents
        insuredPet: $insuredPet
      }
    ) {
      uuid
    }
  }
`
export const SIGN_DOCUMENT = gql`
  mutation signDocument($orderId: ID!, $deliveryMethod: SignDeliveryMethod, $signer1: Signer!) {
    signDocument(input: { orderId: $orderId, signer1: $signer1, deliveryMethod: $deliveryMethod }) {
      signatureId
      url
      deliveryMethod
      status
    }
  }
`
export const SIGNATURE_INFO = gql`
  query getSignatureInfo($id: ID!) {
    getSignatureInfo(id: $id) {
      id
      url
      signatureId
      status
      deliveryMethod
    }
  }
`
export const INSTA_ORDER_UPDATE_AL = gql`
  mutation instaOrderUpdate(
    $uuidOrder: ID!
    $insuranceStartDate: Date
    $insurer: PersonInput
    $insuredPersons: [PersonInput]
    $insuredBusiness: BusinessInput
    $payment: PaymentInput
    $consents: ConsentsInput
    $beneficiaryType: BeneficiaryType
    $beneficiaryPersons: [PersonInput]
    $additionalAttributes: OrderAdditionalAttributesInput
  ) {
    instaOrderUpdate(
      input: {
        uuidOrder: $uuidOrder
        insuranceStartDate: $insuranceStartDate
        insurer: $insurer
        insuredPersons: $insuredPersons
        insuredBusiness: $insuredBusiness
        payment: $payment
        consents: $consents
        beneficiaryType: $beneficiaryType
        beneficiaryPersons: $beneficiaryPersons
        additionalAttributes: $additionalAttributes
      }
    ) {
      uuid
      insurer {
        name
      }
      additionalAttributes {
        placeOfBirth
        localLodgingIsInsurerEqual
        localLodgingAddress
        localLodgingPostalCode
        localLodgingYearConstruction
        localLodgingType
        localLodgingClientsCapacity
        localLodgingRegistryNumber
        localLodgingBuildingOwner
        localLodgingBuildingOwnerName
      }
      insuredBusiness {
        isBusiness
        businessFiscalAddress
        businessCode
        businessCodeOther
        businessActivityDescription
        businessWorkersNumber
        businessAnnualIncome
        businessStartingYear
        businessStructure
        businessStructureOther
        businessRepresentation
        businessNationality
        businessShareholders
        businessFiscalPostalCode
        businessName
      }
    }
  }
`
