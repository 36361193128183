// @flow
import React from 'react'
import styled from 'styled-components'

function parseColor(color: any, pseudo: any) {
  const parsedColor = typeof color === 'object' ? (pseudo ? { ...color[pseudo] } : { ...color }) : color

  // if we are styling a pseudo state use the base color if it was not provided
  if (!parsedColor.base && pseudo) {
    parsedColor.base = color.base
  }

  return parsedColor.base || parsedColor
}

type PropsType = {
  component?: string,
  weight?: number | string,
  lineHeight?: number | string,
  uppercase?: boolean,
  align?: string,
  underline?: boolean,
  truncate?: boolean,
  color?: any,
  transition?: string,
  margin?: string,
  size?: number
}

export default function Text({
  component = 'span',
  weight = 'normal',
  lineHeight = 1.5,
  uppercase,
  align,
  underline,
  truncate,
  color = '#000000',
  transition = 'color 160ms ease',
  margin,
  size,
  ...props
}: PropsType) {
  let styles = `
    text-decoration: ${underline ? 'underline' : 'none'};
    transition: ${transition};
  `

  if (size) {
    styles += `font-size: ${size}px;`
  }

  if (weight) {
    styles += `font-weight: ${weight};`

    if (weight === 'bold') {
      styles += `font-family: Brandon Grotesque Bold;`
    }
  }

  if (lineHeight) {
    styles += `line-height: ${lineHeight};`
  }

  if (uppercase) {
    styles += `
    text-transform: uppercase;
    letter-spacing: 0.05em;
    `
  }

  if (align) {
    styles += `text-align: ${align};`
  }

  if (truncate) {
    styles += `
    display: block;
    whiteSpace: nowrap;
    overflow: hidden;
    textOverflow: ellipsis;
    `
  }

  if (color.base) {
    styles += `color: ${parseColor(color)};`
    if (color.hover) {
      styles += `
      &:hover: {
        color: ${parseColor(color, 'hover')};
      }
      `
    }
    if (color.active) {
      styles += `
      &:active: {
        color: ${parseColor(color, 'active')};
      }
      `
    }
  } else if (color) {
    styles += `color: ${parseColor(color)};`
  }

  if (margin) {
    styles += `margin: ${margin};`
  }

  const StyledText = styled(`${component}`)`
    ${styles}
  `
  const element = React.createElement(StyledText, props)
  return element
}
